import auth0 from 'auth0-js'
import { buildEnv, buildTenantEnv } from '../helpers'

type Auth0ClientConfig = {
  domain: string
  clientID: string
  responseType: string
  redirectUri: string
  audience: string
}

const clientConfig: Auth0ClientConfig = {
  domain: process.env[buildEnv('AUTH0_DOMAIN')],
  clientID: process.env[buildTenantEnv('AUTH0_CLIENT_ID')],
  responseType: 'token id_token',
  redirectUri: `${window.location.origin}${process.env[buildEnv('AUTH0_REDIRECT_URI')]}`,
  audience: process.env[buildEnv('AUTH0_AUDIENCE')],
}

const client = new auth0.WebAuth(clientConfig)
const getAuthClient = () => client

export { getAuthClient }
