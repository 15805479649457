import { Icon, Typography, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CheckIcon from '@material-ui/icons/Check'
import DescriptionIcon from '@material-ui/icons/Description'
import WarningIcon from '@material-ui/icons/Warning'
import classnames from 'classnames'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import arrowIcon from '../../assets/arrow-icon.svg'
import { ROUTES } from '../../constatants'
import {
  getTestHistoryItem,
  selectTestAddress,
  selectTestAppointmentTime,
  selectTestCollectionDate,
  selectTestHistory,
  selectTestLabVendorName,
  selectTestLocation,
  selectTestName,
  selectTestOrderDate,
  selectTestLabVendorPdf,
  selectTestPickupAddress,
  selectTestResult,
  selectTestShipName,
  selectTestStatus,
} from '../../features/test-history/model'
import { Loading } from '../../ui'
import styles from './page.module.css'

export const TestHistoryItemPage = () => {
  const history = useHistory()
  const { testHistoryItemId } = useParams<{ testHistoryItemId: string }>()
  const dispatch = useDispatch()
  const { activeItem, error, isLoading } = useSelector(selectTestHistory)

  React.useEffect(() => {
    dispatch(getTestHistoryItem(testHistoryItemId))
  }, [testHistoryItemId])

  const goToList = () => history.push(ROUTES.TEST_HISTORY)

  if (error) return <p>{error}</p>
  if (isLoading) return <Loading />
  if (!activeItem) return null

  return (
    <div className={`${styles.fullSettingsFrame} container page-content mb-5 pt-0 mt-3`}>
      <div className="row">
        <div className="col-md-12">
          <div className="my-3 pl-3">
            <a onClick={goToList} className={`${styles.arrowBack} cursor-pointer`}>
              <i className="fa fa-arrow-left color-safe mr-2" />
              <span className="color-safe">Go back</span>
            </a>
          </div>
          <div className={`${styles.titleArea} text-uppercase px-2`}>Diagnostic Order</div>
          <TestNameBlock />
          <ReferenceLabBlock />
          <OrderDateBlock />
          <CollectionDateBlock />
          <ShipCarrierBlock />
          <StatusBlock />
          <RegisterKitBlock />
          <PickupAddressBlock />
          <CancelPickupBlock />
          <ResultBlock />
          <AppointmentTime />
          <LocationBlock />
          <AddressBlock />
          <RescheduleBlock />
          <DownloadLabOrder />
          <DownloadLabReportBlock />
        </div>
      </div>
    </div>
  )
}
const TestNameBlock = () => <BaseBlock title={'Test Name'} secondTitle={useSelector(selectTestName)} />
const ReferenceLabBlock = () => {
  const value = useSelector(selectTestLabVendorName)
  if (!value) return null
  return <BaseBlock title={'Reference Lab'} secondTitle={value} />
}
const OrderDateBlock = () => {
  const value = useSelector(selectTestOrderDate)
  if (!value) return null
  return <BaseBlock title={'Order Date'} secondTitle={value} />
}
const CollectionDateBlock = () => {
  const value = useSelector(selectTestCollectionDate)
  if (!value) return null
  return <BaseBlock title={'Collection Date'} secondTitle={value} />
}
const ShipCarrierBlock = () => {
  const value = useSelector(selectTestShipName)
  if (!value) return null
  return <BaseBlock title={'Shipping Carrier'} secondTitle={value} />
}
const StatusBlock = () => {
  const value = useSelector(selectTestStatus)
  return <BaseBlock title={'Status'} secondTitle={value} />
}
const RegisterKitBlock = () => {
  const value = null
  if (!value) return null
  return <BaseBlock title={'Register Kit (Scan code)'} secondTitle={value} />
}
const PickupAddressBlock = () => {
  const value = useSelector(selectTestPickupAddress)
  if (!value) return null
  return <BaseBlock title={'Pickup Address'} secondTitle={value} />
}
const CancelPickupBlock = () => {
  const value = null
  if (!value) return null
  return <BaseBlock title={'Cancel Pickup'} secondTitle={value} />
}
const ResultBlock = () => {
  const result = useSelector(selectTestResult)
  const classes = useResultBlockStyles()
  if (!result) return null

  let EndIcon = null
  if (result === 'NEGATIVE') EndIcon = CheckIcon
  if (result === 'POSITIVE') EndIcon = WarningIcon

  return (
    <BaseBlock
      title={'Result'}
      secondTitle={result}
      secondTitleClass={classnames({
        [classes.negative]: result === 'NEGATIVE',
        [classes.positive]: result === 'POSITIVE',
      })}
      endAdornment={
        EndIcon ? (
          <EndIcon
            className={classnames({
              [classes.negative]: result === 'NEGATIVE',
              [classes.positive]: result === 'POSITIVE',
            })}
          />
        ) : undefined
      }
    />
  )
}
const AppointmentTime = () => {
  const value = useSelector(selectTestAppointmentTime)
  if (!value) return null
  return <BaseBlock title={'Appointment Time'} secondTitle={value} />
}
const LocationBlock = () => {
  const value = useSelector(selectTestLocation)
  if (!value) return null
  return <BaseBlock title={'Location'} secondTitle={value} />
}
const AddressBlock = () => {
  const value = useSelector(selectTestAddress)
  if (!value) return null
  return <BaseBlock title={'Address'} secondTitle={value} />
}
const RescheduleBlock = () => {
  const value = null
  if (!value) return null
  return <BaseBlock title={'Reschedule Appointment'} secondTitle={value} />
}
const useResultBlockStyles = makeStyles({
  negative: {
    color: '#1A9A4C !important',
  },
  positive: {
    color: '#FA9C13 !important',
  },
  unknown: {
    border: '2px solid #FA9C13',
  },
})

type BaseBlockProps = {
  title: string
  secondTitle: string
  className?: string
  secondTitleClass?: string
  endAdornment?: React.ReactNode
}
const BaseBlock = ({ secondTitle, title, className, secondTitleClass, endAdornment }: BaseBlockProps) => {
  const classes = useBaseStyles()
  return (
    <div className={classnames(className, 'pl-3 pr-3 bg-white border-radius-10 mb-3')}>
      <div
        className={`${styles.pageLinkWrapper} color-safe d-flex justify-content-between align-items-center border-0`}
      >
        <div>
          <Typography className={styles.title}>{title}</Typography>
          <Typography className={classnames(classes.secondTitle, secondTitleClass)}>{secondTitle}</Typography>
        </div>
        {endAdornment}
      </div>
    </div>
  )
}
const useBaseStyles = makeStyles({
  secondTitle: {
    fontSize: 14,
    color: '#6d6868',
  },
})
const DownloadLabOrder = () => {
  const pdf = useSelector(selectTestLabVendorPdf)
  if (!pdf) return null

  return (
    <div className={classnames('pl-3 pr-3 bg-white border-radius-10 mb-3')}>
      <Link
        href={`data:application/pdf;base64,${pdf}`}
        download={'order.pdf'}
        className={`${styles.pageLinkWrapper} color-safe d-flex justify-content-between align-items-center border-0`}
      >
        <div className={styles.linkWrapper}>
          <Icon className={styles.linkIcon}>
            <DescriptionIcon />
          </Icon>
          <div>Download lab order</div>
        </div>
        <img
          src={arrowIcon}
          alt="arrow-icon"
          className={`${styles.arrowIcon} mr-3 ml-auto d-flex align-items-center`}
        />
      </Link>
    </div>
  )
}
const DownloadLabReportBlock = () => {
  const value = null
  if (!value) return null
  return <BaseBlock title={'Download Lab Report'} secondTitle={value} />
}
