import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Modal as BootstrapModal } from 'react-bootstrap'
import api from '../../api'
import loaderGif from '../../assets/loader.gif'
import Modal from '../../components/Modal'
import { ROUTES } from '../../constatants'
import { convertArrayBufferToBlobUrl } from '../../libs/utils'
import styles from './Video.module.css'

interface IncomingCallModalProps {
  onClose: () => void
  data: any
  history: any
}

export function IncomingCallModal({ onClose, data, history }: IncomingCallModalProps) {
  const [providerPhoto, setProviderPhoto] = useState(null)
  const openCall = () => {
    const { sessionId, token } = data
    history.push(`${ROUTES.VIDEO}?sessionId=${sessionId}&token=${token}`)
  }

  useEffect(() => {
    if (_.isEmpty(data)) return

    const { caller } = data
    const { email, accountId } = caller

    ;(async function f() {
      const [result, error] = await api.getPicture({ accountId, email })
      if (result) {
        const photoUrl = convertArrayBufferToBlobUrl(result, 'image/png')
        setProviderPhoto(photoUrl)
      }
    })()
  }, [data])

  const generateName = () => {
    const { caller } = data
    const { firstName, lastName, middleName } = caller
    return _.join(_.compact([firstName, middleName, lastName]), ' ') || 'Provider'
  }

  function generateAddress() {
    const { caller } = data
    const { primaryAddress } = caller
    const { line1, line2, city, state, zip, country } = primaryAddress

    return _.join(_.compact([line1, line2, city, state, zip, country]), ', ') || ''
  }

  if (_.isEmpty(data)) return null

  return (
    <Modal>
      <BootstrapModal show={!!data} onHide={onClose}>
        <BootstrapModal.Header closeButton className={`closeBtn`}>
          <BootstrapModal.Title>Incoming call</BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body>
          <div className={`${styles.callContent} py-4 my-auto text-center`}>
            <div className="text-center pb-4">
              <h1 className={`${styles.callerName} ${styles.callerNameModal} h4 mb-2`}>{generateName()}</h1>
              <h4 className={`${styles.callerLocation} mb-0 text-uppercase font-weight-bold small`}>
                {generateAddress()}
              </h4>
            </div>
            <img
              style={{ width: 150, height: 150 }}
              src={providerPhoto || loaderGif}
              alt="Patient Avatar"
              className="rounded-circle mb-4"
            />
          </div>
        </BootstrapModal.Body>
        <BootstrapModal.Footer className="justify-content-center p-0">
          <div
            className={`${styles.incomingCallActions} ${styles.noBorderRadius} d-flex justify-content-center align-items-center position-relative w-100 m-0`}
          >
            <div onClick={onClose} className={`${styles.callActionsInner} text-white d-flex justify-content-between`}>
              <div className="d-flex flex-column align-items-center">
                <i
                  className={`${styles.caIcon} ${styles.caEnd} fa fa-times rounded-circle d-flex align-items-center justify-content-center`}
                  aria-hidden="true"
                />
                <span className="small">Reject</span>
              </div>
              <div onClick={openCall} className="d-flex flex-column align-items-center">
                <i
                  className={`${styles.caIcon} ${styles.caAccept} fa fa-check rounded-circle d-flex align-items-center justify-content-center`}
                  aria-hidden="true"
                />
                <span className="small">Accept</span>
              </div>
            </div>
          </div>
        </BootstrapModal.Footer>
      </BootstrapModal>
    </Modal>
  )
}
