import { FormControl, FormHelperText } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import MUITextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useFormik } from 'formik'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import api from '../../api'
import { DatePicker } from '../../components/date-picker'
import { PhoneInput } from '../../components/phone-input'
import { ROUTES, USAStateList, allowedGenders, listOfCountries } from '../../constatants'
import { useIsFirstLogin } from '../../features/auth0'
import { selectTenantConfig } from '../../features/tenantConfigSlice'
import { selectProfile, setProfile } from '../../features/userSlice'
import { TextField } from '../../ui'
import styles from './account-info.module.css'

const today = new Date()
const minDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate())

const validationSchema = Yup.object().shape({
  birthDate: Yup.date()
    .nullable()
    .typeError('Please enter a valid date')
    .required('Required')
    .max(minDate, 'You must to be at least 18 years old'),
  gender: Yup.string()
    .nullable()
    .oneOf([...allowedGenders.map(({ value }) => value), null], 'Incorrect value')
    .required('Required'),
  phone: Yup.string().nullable().required('Required'),
  street: Yup.string().nullable().required('Required'),
  apt: Yup.string().nullable(),
  city: Yup.string().nullable().required('Required'),
  state: Yup.string().nullable().required('Required'),
  country: Yup.string().nullable().required('Required'),
  zip: Yup.string().nullable().max(6, '6 chars max').required('Required'),
})

export const AccountInfoPage = () => {
  const tenantConfig = useSelector(selectTenantConfig)
  const history = useHistory()

  const user = useSelector(selectProfile)
  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(setProfile())
  }, [])
  useIsFirstLogin()

  const formik = useFormik({
    initialValues: {
      birthDate: user && user.birthDate ? new Date(user.birthDate) : null,
      gender: user ? user.gender : '',
      phone: user ? user.contact.mobileNumber : '',
      code: user ? user.contact.mobileCountryCode : '',
      street: user ? user.primaryAddress.line1 : '',
      apt: user ? user.primaryAddress.line2 || '' : '',
      city: user ? user.primaryAddress.city : '',
      state: user ? user.primaryAddress.state : '',
      country: user ? user.primaryAddress.country || 'USA' : '',
      zip: user ? user.primaryAddress.zip : '',
    },
    onSubmit({ birthDate, gender, apt, city, country, phone, code, state, street, zip }) {
      api
        .updateAccount({
          ...user,
          gender,
          birthDate: new Date(birthDate).toISOString(),
          primaryAddress: {
            ...user.primaryAddress,
            line1: street,
            line2: apt,
            city,
            state,
            country,
            zip,
          },
          contact: {
            ...user.contact,
            mobileNumber: phone,
            faxCode: code,
            homeCountryCode: code,
            mobileCountryCode: code,
            workCountryCode: code,
          },
        })
        .then(([res]) => {
          if (res) history.push(ROUTES.START_CHECKER)
          else window.alertify.warning('Something went wrong...')
        })
    },
    validationSchema,
  })

  return (
    <div className={`${styles.fullSettingsFrame} container-fluid p-0 mt-3`}>
      <form className="form-signin mt-0 mb-0" onSubmit={formik.handleSubmit} noValidate>
        <div className={styles.setPasswordWrapper}>
          <div className={styles.setText}>Account Info</div>
        </div>
        <div className={styles.setPasswordForm}>
          <DatePicker
            required={!!formik.touched.birthDate}
            value={formik.values.birthDate}
            onChange={(e) => formik.setFieldValue('birthDate', e.target.value)}
            onBlur={() => formik.setFieldTouched('birthDate')}
            helperText={formik.touched.birthDate && (formik.errors.birthDate as string)}
          />
          <div className={`${styles.forgotInput} form-label-group`} id="Input_Password_Group">
            <div>
              <FormControl className={styles.formControl}>
                <InputLabel id="demo-simple-select-label" error={!!formik.errors.gender}>
                  Sex*
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formik.values.gender}
                  onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                    formik.setFieldValue('gender', event.target.value as string)
                  }}
                  required
                  fullWidth
                >
                  {allowedGenders.map(({ label, value }) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {formik.errors.gender && formik.touched.gender && (
              <FormHelperText error>{formik.errors.gender}</FormHelperText>
            )}
          </div>
          <PhoneInput
            value={formik.values.phone}
            code={formik.values.code}
            onChange={({ value, code }) => {
              formik.setFieldValue('phone', value)
              formik.setFieldValue('code', code)
            }}
            onBlur={() => formik.setFieldTouched('phone')}
            helperText={formik.touched.phone && formik.errors.phone}
            required
          />
          <h2 className={styles.homeHeader}>Home address</h2>
          <TextField
            label={'Street Address'}
            value={formik.values.street}
            onChange={formik.handleChange}
            name={'street'}
            required
            error={formik.touched.street && formik.errors.street}
            onBlur={() => formik.setFieldTouched('street')}
          />
          <TextField
            label={'Apt/Ste'}
            onChange={formik.handleChange}
            value={formik.values.apt}
            name={'apt'}
            onBlur={() => formik.setFieldTouched('apt')}
          />
          <TextField
            label={'City'}
            onChange={formik.handleChange}
            value={formik.values.city}
            name={'city'}
            error={formik.touched.city && formik.errors.city}
            onBlur={() => formik.setFieldTouched('city')}
            required
          />
          <Autocomplete
            options={USAStateList}
            getOptionLabel={({ label }) => label}
            value={USAStateList.find(({ value }) => formik.values.state === value)}
            disableClearable
            onChange={(e, newValue: { value: string; label: string }) => {
              formik.setFieldValue('state', newValue.value)
            }}
            renderInput={(params) => (
              <MUITextField
                {...params}
                label={'State'}
                required
                error={formik.touched.state && !!formik.errors.state}
                helperText={formik.errors.state}
                onBlur={() => formik.setFieldTouched('state')}
              />
            )}
          />
          <Autocomplete
            options={listOfCountries}
            getOptionLabel={({ label }) => label}
            value={listOfCountries.find(({ value }) => formik.values.country === value)}
            disableClearable
            onChange={(e, newValue: { value: string; label: string }) => {
              formik.setFieldValue('country', newValue.value)
            }}
            renderInput={(params) => (
              <MUITextField
                {...params}
                label={'Country'}
                required
                error={formik.touched.country && !!formik.errors.country}
                helperText={formik.errors.country}
                onBlur={() => formik.setFieldTouched('country')}
              />
            )}
          />
          <TextField
            onChange={formik.handleChange}
            value={formik.values.zip}
            label={'Zip Code'}
            required
            name={'zip'}
            error={formik.touched.zip && formik.errors.zip}
            onBlur={() => formik.setFieldTouched('zip')}
          />
          <div className={styles.continueBtn}>
            <button
              style={{ backgroundColor: tenantConfig.appThemeColor }}
              className={`${styles.noOutline} ${styles.submitBtn} btn btn-lg btn-block text-white d-flex align-items-center justify-content-center`}
              type="submit"
              disabled={formik.isSubmitting}
            >
              Continue
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}
