import { FormControl, FormLabel, FormHelperText } from '@material-ui/core'
import { DatePicker as KendoDatePicker, DatePickerChangeEvent } from '@progress/kendo-react-dateinputs'
import React, { useRef } from 'react'
import styles from './date-picker.module.css'

type DatePickerProps = {
  required: boolean
  value: Date
  onChange: (e: DatePickerChangeEvent) => void
  onBlur: (e: DatePickerChangeEvent) => void
  helperText: string
}
export const DatePicker: React.FC<DatePickerProps> = ({ value, required, onChange, onBlur, helperText }) => {
  const datePickerContainer = useRef(null)
  const datePicker = useRef(null)

  const onBlurElement = (e) => {
    if (!e.relatedTarget) datePicker.current.setShow(false)
    onBlur(e)
  }

  return (
    <FormControl ref={datePickerContainer} className={styles.dobControl} required>
      <FormLabel error={!!helperText}>Date of Birth</FormLabel>
      <KendoDatePicker
        className={styles.datePicker}
        ref={datePicker}
        required={required}
        value={value}
        onChange={onChange}
        onBlur={onBlurElement}
      />
      <FormHelperText error>{helperText}</FormHelperText>
    </FormControl>
  )
}
