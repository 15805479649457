import { createSlice } from '@reduxjs/toolkit'
import api from '../api'
import { AppThunk, RootState } from '../app/store'
import { getTenantName } from '../libs/helpers'

type State = {
  data: any
  lastQuestionnaire: string
  outcomeState: 'SYMPTOMATIC' | 'ASYMPTOMATIC' | 'EXPOSURE'
}
const initialState: State = {
  data: null,
  lastQuestionnaire: '',
  outcomeState: 'EXPOSURE',
}

export const latestResultSlice = createSlice({
  name: 'latestResult',
  initialState,
  reducers: {
    setLatestResult: (state: State, action) => ({
      ...state,
      data: action.payload,
      lastQuestionnaire: action.payload.outcome.lastQuestionnaire,
      outcomeState: action.payload.outcome.outcome,
    }),
  },
})

export const { setLatestResult } = latestResultSlice.actions

export const getLatestResult = (): AppThunk => async (dispatch) => {
  const tenant = getTenantName()
  const [result] = await api.getLatestResult(tenant)
  if (result) dispatch(setLatestResult(result))
}

export const selectLatestResult = (state: RootState) => state.latestResult

export default latestResultSlice.reducer
