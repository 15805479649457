import { format } from 'date-fns'

export function randomString(length = 32) {
  const bytes = new Uint8Array(length)
  const result = []
  const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~'

  const { crypto } = window
  const random = crypto.getRandomValues(bytes)
  for (let i = 0; i < random.length; i += 1) result.push(charset[random[i] % charset.length])

  return result.join('')
}

export const N = (number) => {
  number = +number || 0
  return Math.round(number * 100000000) / 100000000
}

export function convertArrayBufferToBlobUrl(arrayBuffer, mime) {
  let blobParts = []
  if (Array.isArray(arrayBuffer)) blobParts = arrayBuffer
  else blobParts.push(arrayBuffer)
  return URL.createObjectURL(new Blob(blobParts, { type: mime }))
}

export function dataURLtoBlob(url) {
  let arr = url.split(',')
  let mime = arr[0].match(/:(.*?);/)[1]
  let bstr = atob(arr[1])
  let n = bstr.length
  let u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], { type: mime })
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const getAtSeparatedDate = (stringDate: string, template = 'MM/dd/yyyy @ p') => {
  return format(new Date(stringDate), template)
}
