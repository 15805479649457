import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import { ThemeButton } from '../../../components/ThemeButton/ThemeButton'
import { ROUTES } from '../../../constatants'
import { selectConfig } from '../../../features/configSlice'
import { selectProfile } from '../../../features/userSlice'
import { getTenantName } from '../../../libs/helpers'
import { Loading } from '../../../ui'
import { selectQuestionnaire } from '../checkerSlice'
import styles from './Completed.module.css'
import testStyles from './Test.module.css'

export function Completed({ history }: RouteProps) {
  const profile = useSelector(selectProfile)
  const questionnaire = useSelector(selectQuestionnaire)
  const config = useSelector(selectConfig)

  const doComplete = () => {
    history.push(ROUTES.DASHBOARD)
  }

  if (!questionnaire) return <Loading />

  const { metadata } = profile
  const isIFSUserSegment = metadata['User Segment'] && /IFS/gi.test(metadata['User Segment'])

  const { questionnaire: questionnaireObj } = questionnaire

  const { option, outcomeImage, outcomeMessage, userSegmentSettings } = questionnaireObj.outcome
  const text = isIFSUserSegment ? userSegmentSettings.customOutcomeMessage || outcomeMessage : outcomeMessage

  const { outcomesActions } = config

  const renderText = () => {
    if (!text) return null

    return <div className={testStyles.checkerResultText} dangerouslySetInnerHTML={{ __html: text }} />
  }

  const renderButtons = () => {
    const tenant = getTenantName()

    if (tenant === 'delta') {
      return (
        <ThemeButton onClick={doComplete} type={'standard'}>
          OK
        </ThemeButton>
      )
    }

    return _.map(outcomesActions[option] ? outcomesActions[option].steps : [], (step) => {
      return (
        <ThemeButton onClick={doComplete} type={'standard'}>
          {step.label}
        </ThemeButton>
      )
    })
  }

  return (
    <div className={`${styles.fullSettingsFrame} container mt-3 flex-grow d-flex flex-wrap`} data-page-title="Checker">
      <div className={classNames(styles.flex1, 'diagnostic-wrapper text-center mx-auto d-flex flex-grow')}>
        <div className="row flex-grow flex-column">
          <div className={classNames(styles.content, 'image-result-wrapper my-auto')}>
            <div>
              <img src={outcomeImage} alt="health" className={`${styles.healthImage} mb-4`} />
              {renderText()}
            </div>
            <div className={'mt-5'}>{renderButtons()}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
