import { InputAdornment, TextField as MUITextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

type TextFieldProps = {
  value?: any
  onChange?: (e: React.ChangeEvent) => void
  type?: 'password' | 'text' | 'email' | 'date'
  label: string
  required?: boolean
  disabled?: boolean
  readOnly?: boolean
  error?: string
  endAdornment?: React.ReactNode
  max?: string
  onBlur?: (e: React.FocusEvent) => void
  inputRef?: React.RefObject<HTMLInputElement>
  name?: string
  color?: string
  autoFocus?: boolean
  fullWidth?: boolean
}
export const TextField: React.FC<TextFieldProps> = ({
  onChange,
  value,
  type = 'text',
  label,
  required = false,
  disabled = false,
  readOnly = false,
  error,
  endAdornment,
  max,
  onBlur,
  inputRef,
  name,
  color,
  autoFocus = false,
  fullWidth = false,
}) => {
  const classes = useStyles({ color })
  let endAdornmentProp
  let shrinkProp

  if (endAdornment) {
    endAdornmentProp = <InputAdornment position={'end'}>{endAdornment}</InputAdornment>
  }

  if (type === 'date') {
    shrinkProp = true
  }

  return (
    <MUITextField
      onChange={onChange}
      value={value}
      className={classes.root}
      type={type}
      label={label}
      required={required}
      disabled={disabled}
      error={!!error}
      helperText={error}
      aria-readonly={readOnly}
      InputProps={{
        endAdornment: endAdornmentProp,
        readOnly,
      }}
      InputLabelProps={{
        shrink: shrinkProp,
        className: classes.labelRoot,
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={{
        max,
      }}
      onBlur={onBlur}
      ref={inputRef}
      name={name}
      autoFocus={autoFocus}
      fullWidth={fullWidth}
    />
  )
}

const useStyles = makeStyles({
  root: {
    '& label.Mui-focused': {
      color: ({ color }: { color: string }) => color,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: ({ color }: { color: string }) => color,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: ({ color }: { color: string }) => color,
      },
    },
  },
  labelRoot: {
    fontWeight: 500,
    color: ({ color }: { color: string }) => color,
  },
})
