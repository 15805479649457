import { createSlice } from '@reduxjs/toolkit'
import api from '../api'
import { AppThunk, RootState } from '../app/store'
import { getTenantName } from '../libs/helpers'

const initialState = null

export const verbiageSlice = createSlice({
  name: 'verbiage',
  initialState,
  reducers: {
    setVerbiage: (state, { payload }) => payload,
  },
})

export const { setVerbiage } = verbiageSlice.actions

export const getVerbiage = (): AppThunk => async (dispatch, getState) => {
  let tenant = getTenantName()
  const [result] = await api.getVerbiage(tenant)
  dispatch(setVerbiage(result))
}

export const selectVerbiage = (state: RootState) => state.verbiage

export default verbiageSlice.reducer
