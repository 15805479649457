import axios from 'axios'
import { store } from '../app/store'
import { doLogout } from '../features/deviceSlice'
import { User } from '../features/userSlice'
import { getTenantName, createResourcesLink } from '../libs/helpers'
import httpClient from './httpClient'

const API = {
  async startChecker() {
    const tenant = getTenantName()
    function getStrangePrefix(t) {
      if (t === 'delta') {
        return '5f20763b0b96011bca83ea9e'
      }

      return '5f1c3c672fec9272e58e962d'
    }
    return doHttpCall('POST', `/questionnaire/exec/start/${getStrangePrefix(tenant)}`, { tenant })
  },

  async nextQuestion(id, data) {
    return doHttpCall('POST', `/questionnaire/exec/next/${id}`, data)
  },

  prevQuestion(questionnaireId: string) {
    return doHttpCall('POST', `/questionnaire/exec/back/${questionnaireId}`, {})
  },

  async getConfigs() {
    return doHttpCall('GET', `/admin/tenant/${getTenantName()}/config`, {})
  },

  async getProfile() {
    return doHttpCall('GET', `/admin/user`, {})
  },

  async getPicture(data = { accountId: '', email: '' }) {
    return doHttpCall('GET_PHOTO', '/admin/user/pic', data)
  },

  async updatePicture(data) {
    return doHttpCall('POST_PHOTO', '/admin/user/pic', data)
  },

  resetPassword({ id, password }) {
    return doHttpCall('POST', `/admin/user/reset-password?id=${id}`, { password })
  },

  updateAccount(data: User) {
    return doHttpCall('PUT', `/admin/user`, data)
  },

  async getUserCode(data) {
    return doHttpCall('GET_PHOTO', '/admin/user/code?width=200', data)
  },

  async getTenantConfig(tenant) {
    return doHttpCall('GET', `/content/network/for/tenant/${tenant}`, {})
  },

  async getResourcesList() {
    return getContentHttpCall(createResourcesLink().basic)
  },

  async getResourcePrimaryVideo() {
    return getContentHttpCall(createResourcesLink().primary)
  },

  async updateDeviceInfo(data) {
    return doHttpCall('PUT', '/admin/user/device', data)
  },

  async getVerbiage(tenant) {
    return doHttpCall('GET', `/admin/verbiage/${tenant}/rendered`, {})
  },

  async getFaq(tenant) {
    return doHttpCall('GET', `/admin/faq/${tenant}`, {})
  },

  async getConsent(tenant) {
    return doHttpCall('GET', `/admin/user/consent/${tenant}?direction=DESC&limit=999&skip=0&sort=consented`, {})
  },

  async submitConsent(tenant, verbiageKey) {
    return doHttpCall('POST', `/admin/user/consent/${tenant}?verbiageKey=${verbiageKey}`, {})
  },

  async getLatestResult(tenant) {
    return doHttpCall('GET', `admin/laboratory-test/byUser/${tenant}/latest-result`, {})
  },

  getTestHistory(page: number) {
    const tenant = getTenantName()
    return doHttpCall('GET', `lab-order/order/getorders/${tenant}`, {
      pageNumber: page - 1,
      pageSize: 20,
    })
  },

  getTestHistoryItem(itemId: string) {
    return doHttpCall('GET', `lab-order/order/getorderdetails/${itemId}`, {})
  },

  async getSelfAssessmentHistory(limit: number, page: number) {
    return doHttpCall('GET', `admin/user/questionnaire/delta?direction=DESC&limit=${limit}&skip=${page}&sort=completed`, {})
  },
}

export default API

function createResponse(result: any, error?: any) {
  return [result, error]
}

async function doHttpCall(method, url: string, payload: any) {
  try {
    let result = null
    let headers = {}

    const accessToken = localStorage.getItem('access_token')
    const idToken = localStorage.getItem('id_token')

    if (accessToken && idToken) {
      headers['Authorization'] = `Bearer ${accessToken}`
      headers['Id_Token'] = idToken
    }

    if (method === 'POST') {
      result = await httpClient.post(url, { ...payload }, { headers })
    } else if (method === 'PUT') {
      result = await httpClient.put(url, { ...payload }, { headers })
    } else if (method === 'GET') {
      result = await httpClient.get(url, { headers, params: payload })
    } else if (method === 'GET_PHOTO') {
      const { accountId, email } = payload
      let query = ''
      const idAndEmail = [accountId, email].filter(Boolean).join('/')
      if (accountId || email) query = `?id=${idAndEmail}`
      result = await httpClient.get(`${url}${query}`, { headers, params: payload, responseType: 'arraybuffer' })
    } else if (method === 'POST_PHOTO') {
      let formData = new FormData()
      formData.append('image', payload)
      result = await httpClient.post(url, formData, {
        headers: { ...headers, 'Content-Type': 'multipart/form-data' },
      })
    }

    if (!(result && result.data)) {
      return createResponse(null, { message: 'Data not found' })
    }

    return createResponse(result.data)
  } catch (error) {
    console.log(error)
    if (error.response && error.response.status === 401) {
      await store.dispatch(doLogout(true))
    }
    return createResponse(null, error)
  }
}

async function getContentHttpCall(url) {
  let result = null
  let headers = {
    'Content-Type': 'text/plain',
  }

  try {
    result = await axios.get(url, { headers })
    if (!result) {
      return createResponse(null, { message: 'Data not found' })
    }

    return createResponse(result.data)
  } catch (e) {
    return createResponse(null, e)
  }
}
