import { createSlice } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import api from '../../api'
import { AppThunk, RootState } from '../../app/store'
import { getAtSeparatedDate } from '../../libs/utils'
import { TestHistoryState, TestHistoryContract, TestHistoryItemContract } from './types'

const initialState: TestHistoryState = {
  isLoading: false,
  list: [],
  activeItem: null,
  error: '',
  page: 1,
  isDone: false,
}
export const testHistorySlice = createSlice({
  name: 'testHistory',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.isLoading = payload
    },
    setList: (state, { payload }) => {
      state.isLoading = false
      state.list = payload
      state.page = 1
      state.isDone = false
    },
    setListMore: (state, { payload: { list, page } }) => {
      state.page = page
      state.list = [...state.list, ...list]
      if (list.length === 0) state.isDone = true
    },
    setActiveItem: (state, { payload }) => {
      state.isLoading = false
      state.activeItem = payload
    },
    setError: (state, { payload }) => {
      state.error = payload
    },
  },
})

const { setList, setLoading, setActiveItem, setError, setListMore } = testHistorySlice.actions

export const getTestHistory = (): AppThunk => async (dispatch) => {
  dispatch(setLoading(true))
  const [res]: [TestHistoryContract] = (await api.getTestHistory(1)) as [TestHistoryContract]
  dispatch(setList(res.data))
}

export const getTestHistoryMore = (): AppThunk => async (dispatch, getState) => {
  const page = getState().testHistory.page + 1
  const [res]: [TestHistoryContract] = (await api.getTestHistory(page)) as [TestHistoryContract]
  dispatch(setListMore({ list: res.data, page }))
}

export const getTestHistoryItem = (itemId: string): AppThunk => async (dispatch) => {
  dispatch(setLoading(true))
  const [res, err]: [TestHistoryItemContract, AxiosError] = (await api.getTestHistoryItem(itemId)) as [
    TestHistoryItemContract,
    any,
  ]
  if (err) dispatch(setError(err.response.data.Message))
  else dispatch(setActiveItem(res.data))
}

export const selectTestHistory = (state: RootState) => state.testHistory
export const selectTestName = (state: RootState) => {
  return state.testHistory.activeItem?.details?.orderableTest?.customName || '-'
}
export const selectTestLabVendorName = (state: RootState) => {
  return state.testHistory.activeItem?.details?.labVendor?.name || null
}
export const selectTestOrderDate = (state: RootState) => {
  if (!state.testHistory.activeItem?.created) return null
  return getAtSeparatedDate(state.testHistory.activeItem.created, 'MM/dd/yyyy p')
}
export const selectTestCollectionDate = (state: RootState) => {
  if (!state.testHistory.activeItem?.collectionDate) return null
  return getAtSeparatedDate(state.testHistory.activeItem.collectionDate, 'MM/dd/yyyy p')
}
export const selectTestShipName = (state: RootState) => {
  return state.testHistory.activeItem?.details?.shippingCarrier?.name || null
}
export const selectTestStatus = (state: RootState) => {
  return state.testHistory.activeItem?.statusDisplayText || '-'
}
export const selectTestPickupAddress = ({ testHistory }: RootState) => {
  const { error, activeItem, isLoading } = testHistory
  if (error || isLoading || !activeItem) return ''
  if (!activeItem.details?.pickupDetails?.address) return ''

  const { address1, address2, city, state, zip } = activeItem.details.pickupDetails.address
  return [address1, address2, city, state, zip].filter(Boolean).join(', ')
}
export const selectTestResult = ({ testHistory }: RootState) => {
  if (testHistory.activeItem?.status === 'COMPLETED') return testHistory.activeItem.result
  return null
}
export const selectTestAppointmentTime = ({ testHistory }: RootState) => {
  if (!testHistory.activeItem?.details?.appointment?.appointmentStart) return null
  return getAtSeparatedDate(testHistory.activeItem.details.appointment.appointmentStart, 'EEEE MM/dd/yyyy p')
}
export const selectTestLocation = ({ testHistory }: RootState) => {
  return testHistory.activeItem?.details?.appointment?.locationName || null
}
export const selectTestAddress = ({ testHistory }: RootState) => {
  const { error, activeItem, isLoading } = testHistory
  if (error || isLoading || !activeItem) return null
  if (!activeItem.details?.facility) return null

  const {
    homeAddressAddressOne,
    homeAddressAddressTwo,
    homeAddressAddressCity,
    homeAddressState,
    homeAddressZip,
  } = activeItem.details.facility
  return [homeAddressAddressOne, homeAddressAddressTwo, homeAddressAddressCity, homeAddressState, homeAddressZip]
    .filter(Boolean)
    .join(', ')
}
export const selectTestLabVendorPdf = ({ testHistory }: RootState) => {
  const { error, activeItem, isLoading } = testHistory
  if (error || isLoading || !activeItem) return null
  if (!activeItem.details?.labVendor?.isRequisitionSupported) return null
  return activeItem.requisitionBase64 || null
}
