import { useEffect } from 'react'

export function extendThemeContext(tenantConfig) {
  return tenantConfig
}

export function getTenantName() {
  let tenant = process.env.REACT_APP_DEFAULT_TENANT
  const envMode = process.env.REACT_APP_ENV_MODE.toUpperCase()

  const tenantsMap = {
    DEV: {
      delta: 'delta',
      mayo: 'mayo-clinic',
      asu: 'asu',
      health: 'enterprise',
      safe: 'safe',
    },
    QA: {
      delta: 'delta',
      mayo: 'mayoclinic',
      asu: 'asu',
      health: 'healthcheck-v2',
      safe: 'safe',
    },
    UAT: {
      delta: 'delta',
      mayo: 'mayo-clinic',
      asu: 'asu',
      health: 'enterprise',
      safe: 'safe',
    },
    PROD: {
      delta: 'delta',
      mayo: 'mayo-clinic',
      asu: 'asu',
      health: 'health',
      safe: 'safe',
    },
  }

  const hostName = window.location.host
  const tenantsList = tenantsMap[envMode]

  if (/my-delta/.test(hostName)) {
    tenant = tenantsList.delta
  } else if (/my-mayo/.test(hostName)) {
    tenant = tenantsList.mayo
  } else if (/my-asu/.test(hostName)) {
    tenant = tenantsList.asu
  } else if (/my-safe/.test(hostName)) {
    tenant = tenantsList.safe
  } else if (/my-health|localhost/.test(hostName)) {
    tenant = tenantsList.health
  }
  return tenant
}

export function buildEnv(env) {
  const { REACT_APP_ENV_MODE } = process.env
  return `REACT_APP_${REACT_APP_ENV_MODE.toUpperCase()}_${env}`
}

export function buildTenantEnv(env) {
  const { REACT_APP_ENV_MODE } = process.env
  const tenant = getTenantName()
    .toUpperCase()
    .replace(/[^A-Z]/, '')
  return `REACT_APP_${REACT_APP_ENV_MODE.toUpperCase()}_${tenant}_${env}`
}

export function buildPureTenantEnv(env) {
  const tenant = getTenantName()
    .toUpperCase()
    .replace(/[^A-Z]/, '')
  return `REACT_APP_${tenant}_${env}`
}

export function buildFirebaseConfig() {
  const { REACT_APP_ENV_MODE } = process.env
  const config = process.env[`REACT_APP_${REACT_APP_ENV_MODE.toUpperCase()}_FIREBASE_CONFIG`]
  return JSON.parse(decodeURIComponent(config))
}

export function createResourcesLink() {
  const tenant = getTenantName()
  const { REACT_APP_ENV_MODE } = process.env
  let envString = ''
  if (/dev/gi.test(REACT_APP_ENV_MODE)) envString = 'dev'
  else if (/uat/gi.test(REACT_APP_ENV_MODE)) envString = 'stage'
  else if (/qa/gi.test(REACT_APP_ENV_MODE)) envString = 'qa'
  else envString = REACT_APP_ENV_MODE.toLocaleLowerCase()

  return {
    basic: `https://safe-content-cache.s3-us-west-2.amazonaws.com/${envString}/api-responses/resource-videos-${tenant.toLowerCase()}.json`,
    primary: `https://safe-content-cache.s3-us-west-2.amazonaws.com/${envString}/api-responses/resource-primary-video-${tenant.toLowerCase()}.json`,
  }
}

export function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return
      }

      handler(event)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])
}
