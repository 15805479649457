import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ROUTES } from '../../constatants'
import { ThemeContext } from '../../features/auth0'
import { getConsent, selectConsent, submitConsent } from '../../features/consentSlice'
import { getVerbiage, selectVerbiage } from '../../features/verbiageSlice'
import { Loading } from '../../ui'
import styles from './Consent.module.css'

export function Consent({ history }: RouteProps) {
  const dispatch = useDispatch()
  const verbiage = useSelector(selectVerbiage)
  const consents = useSelector(selectConsent)
  const [disabledButton, setDisabledButton] = useState(false)
  const [activeSection, setActiveSection] = useState('')
  const docKey = 'IntroductionText'
  const docs = ['TermsOfService', 'PrivacyPolicy', 'NNPBooklet', 'Consent', 'ServiceAgreement']

  useEffect(() => {
    dispatch(getVerbiage())
    dispatch(getConsent())
  }, [])

  const acceptConsent = async () => {
    setDisabledButton(true)
    await Promise.all(
      docs.map(async (key) => {
        await dispatch(submitConsent(key))
      }),
    )
    setDisabledButton(false)
    history.push(ROUTES.NEW_PASSWORD)
  }

  if (!(verbiage && consents)) {
    return <Loading />
  }

  const doc = verbiage.find((v) => v.key === docKey)

  let consented = true
  docs.forEach((dk) => {
    consented = Boolean(consents.items.find((v) => v.key === dk && v.consented && !v.archived))
  })

  const alphIndex = ['A', 'B', 'C', 'D', 'E', 'F', 'G']

  const renderSectionHtml = (key) => {
    const activeDocSection = _.find(verbiage, (v) => v.key === key)
    if (!activeDocSection) return null
    const alph = alphIndex[0] || ''
    alphIndex.shift()

    return (
      <ThemeContext.Consumer>
        {(theme: any) => (
          <>
            <div
              onClick={() => changeActiveSection(activeDocSection.key)}
              className={`${styles.pageLinkWrapper} ${styles.topBorder} d-flex justify-content-between`}
            >
              <div className="font-weight-bold" style={{ color: theme.appThemeColor }}>
                {alph}. {activeDocSection.title}
              </div>
              <i
                className={`fa ${renderChevronIcon(activeDocSection.key)} ml-auto d-flex align-items-center`}
                style={{ color: theme.appThemeColor }}
              />
            </div>
            {activeSection === key && (
              <div className="pt-3 pb-3">
                <div dangerouslySetInnerHTML={{ __html: activeDocSection.value }} />
              </div>
            )}
          </>
        )}
      </ThemeContext.Consumer>
    )
  }

  const changeActiveSection = (key) => {
    if (key === activeSection) return setActiveSection('')
    setActiveSection(key)
  }

  const renderChevronIcon = (key) => {
    return activeSection === key ? 'fa-chevron-up' : 'fa-chevron-down'
  }

  const renderConsent = (theme) => {
    if (consented) return null

    return (
      <div className="pt-3 mb-3">
        <button
          style={{ backgroundColor: theme.appThemeColor }}
          onClick={acceptConsent}
          disabled={disabledButton}
          className={`${styles.noOutline} ${styles.signInBtn} btn btn-lg btn-block text-white d-flex align-items-center justify-content-center`}
          type="button"
        >
          {doc.confirmationConsentBtnLabel}
        </button>
      </div>
    )
  }

  return (
    <ThemeContext.Consumer>
      {(theme: any) => (
        <div className={`${styles.fullSettingsFrame} container-fluid mb-5 mt-3`}>
          <div className="row">
            <div className={`${styles.providerAppHolder} col-md-12 pb-5`}>
              <div className={`${styles.termsTitle} text-center mb-1`}>Important Information</div>
              <div dangerouslySetInnerHTML={{ __html: doc.value }} />
              {docs.map(renderSectionHtml)}
              {renderConsent(theme)}
            </div>
          </div>
        </div>
      )}
    </ThemeContext.Consumer>
  )
}
