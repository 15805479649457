import { CircularProgress } from '@material-ui/core'
import classNames from 'classnames'
import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import LogoSignInMinimal from '../../assets/logo_minimal.png'
import { IconButton } from '../../components'
import { ThemeInput } from '../../components/ThemeInput/ThemeInput'
import { ThemeContext } from '../../features/auth0'
import { VisibilityIcon, VisibilityOffIcon } from '../../icons'
import { getAuthClient } from '../../libs/auth0'
import { buildEnv, buildTenantEnv } from '../../libs/helpers'
import { randomString } from '../../libs/utils'
import { TextField } from '../../ui'
import styles from './SignIn.module.css'
import { loginFormValidationSchema, forgotPasswordValidationSchema } from './validationSchema'

export function SignIn() {
  const [ready, setReady] = useState(false)
  const [isSubmitInProgress, setSubmitInProgress] = useState(false)
  const [isForgotPassword, setForgotPassword] = useState(false)
  const [forgotPasswordText, setForgotPasswordText] = useState('')
  const [isShowPassword, setIsShowPassword] = useState(false)
  const emailInputRef = React.useRef<HTMLInputElement>(null)
  const auth0 = getAuthClient()

  const doSSORedirection = () => {
    auth0.authorize({
      connection: process.env[buildTenantEnv('SSO_CONNECTION')],
      domain: process.env[buildEnv('AUTH0_DOMAIN')],
      clientID: process.env[buildTenantEnv('AUTH0_CLIENT_ID')],
      responseType: 'token id_token',
      redirectUri: window.location.origin + process.env[buildEnv('AUTH0_REDIRECT_URI')],
      audience: process.env[buildEnv('AUTH0_AUDIENCE')],
    })
  }

  useEffect(() => {
    const { REACT_APP_ENV_MODE } = process.env
    if (/prod/i.test(REACT_APP_ENV_MODE)) {
      return doSSORedirection()
    }
    return auth0.authorize()
  }, [])

  React.useEffect(() => {
    if (!emailInputRef.current) return
    emailInputRef.current.focus()
  }, [ready])

  const submitLogin = ({ email, password }) => {
    const state = randomString()
    const nonce = randomString()
    localStorage.setItem('state', state)
    localStorage.setItem('nonce', nonce)

    setSubmitInProgress(true)

    getAuthClient().login(
      {
        username: email,
        password,
        realm: process.env[buildTenantEnv('AUTH0_REALM')],
        state,
        nonce,
      },
      (res) => {
        setSubmitInProgress(false)
        if (res.error) window.alertify.error(res.error_description)
      },
    )
  }

  const submitForgotPassword = (values) => {
    setSubmitInProgress(true)
    setForgotPasswordText('')
    getAuthClient().changePassword(
      {
        connection: process.env[buildTenantEnv('AUTH0_REALM')],
        email: values.email,
      },
      (err, resp) => {
        setSubmitInProgress(false)
        if (err) return window.alertify.error(err.description)
        setForgotPasswordText(resp)
      },
    )
  }

  const renderSSOButton = (theme) => {
    const { REACT_APP_ENV_MODE } = process.env
    if (['DEV', 'QA', 'dev', 'qa'].includes(REACT_APP_ENV_MODE)) return null
    const ssoClassName = classNames(
      styles.noOutline,
      styles.signInBtn,
      styles.companySignin,
      'btn',
      'btn-lg',
      'btn-block',
      'text-capitalize',
      'd-flex',
      'align-items-center',
      'justify-content-center',
      'border-safe',
      'color-safe',
      'border-strong',
    )

    return (
      <div className="mt-3">
        <button onClick={doSSORedirection} className={ssoClassName}>
          <img src={LogoSignInMinimal} className={styles.buttonIcon} alt="favicon-icon" />
          <div className={styles.ssoButtonText}>Continue with {theme.companyName}</div>
        </button>
      </div>
    )
  }

  const toggleShowPassword = () => setIsShowPassword(!isShowPassword)
  const preventDefault = (e: React.MouseEvent) => e.preventDefault()
  const toggleForgotPassword = () => setForgotPassword(!isForgotPassword)

  const renderForm = (theme) => (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={loginFormValidationSchema}
      onSubmit={submitLogin}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
        <form onSubmit={handleSubmit} className="form-signin m-0">
          <div className={styles.logoContainer}>
            <img style={{ width: 180 }} src={theme.networkLogoInThemeColor} alt={'logo'} />
          </div>
          <div className={styles.signInFormGroupContainer}>
            <div className="form-label-group" id="Input_Email_Group">
              <TextField
                required
                label="Email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={touched.email ? (errors.email as string) : ''}
                type="email"
                autoFocus
                fullWidth
              />
            </div>
            <div className="form-label-group mt-4" id="Input_Password_Group">
              <TextField
                required
                name="password"
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                error={touched.password ? (errors.password as string) : ''}
                label="Password"
                type={isShowPassword ? 'text' : 'password'}
                endAdornment={
                  <IconButton onClick={toggleShowPassword} onMouseDown={preventDefault} className={styles.icon}>
                    {isShowPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                }
              />
            </div>
            <div className={`checkbox mt-3 d-flex justify-content-end ${styles.signinMoreOption}`}>
              <button
                style={{ color: theme.appThemeColor, border: 0, background: 'transparent' }}
                className="nav-link cursor-pointer text-uppercase p-0"
                onClick={toggleForgotPassword}
                type={'button'}
              >
                Forgot password?
              </button>
            </div>
          </div>
          <div className={styles.signinButtonWrapper}>
            <button
              style={{ backgroundColor: theme.appThemeColor }}
              className={`${styles.noOutline} ${styles.signInBtn} btn btn-lg btn-block text-white d-flex align-items-center justify-content-center`}
              type="submit"
              disabled={Object.keys(errors).length > 0 || isSubmitInProgress}
            >
              {isSubmitInProgress ? (
                <CircularProgress className={styles.progress} size="sm" role="status" />
              ) : (
                'Sign in'
              )}
            </button>
          </div>
          {renderSSOButton(theme)}
          <div className={styles.signInErrorMessage}>
            <div className="warning-img-wrapper">
              <div className="warning-img" />
            </div>
            <div />
          </div>
        </form>
      )}
    </Formik>
  )

  const renderForgotPasswordForm = (theme) => (
    <Formik
      initialValues={{
        email: '',
      }}
      validationSchema={forgotPasswordValidationSchema}
      onSubmit={submitForgotPassword}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
        <form onSubmit={handleSubmit} className={`${styles.forgotForm} form-signin mt-0 mb-0`}>
          <div className={styles.forgotWrapper}>
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              className={styles.backImage}
              onClick={() => setForgotPassword(!isForgotPassword)}
            >
              <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="icon/color/Back_Arrow" fill={theme.appThemeColor}>
                  <path
                    // eslint-disable-next-line max-len
                    d="M11.7429911,4.34128332 C12.0788092,4.72485025 12.0689108,5.29267242 11.7385746,5.66390091 L11.6493294,5.75239193 L5.65499321,10.9999932 L22,11 C22.5522847,11 23,11.4477153 23,12 C23,12.5128358 22.6139598,12.9355072 22.1166211,12.9932723 L22,13 L5.66399321,12.9999932 L11.6587167,18.2476217 C12.0422836,18.5834397 12.1075193,19.1475889 11.8291451,19.5592203 L11.7523783,19.6587303 C11.4165603,20.0422972 10.8524111,20.1075329 10.4407797,19.8291587 L10.3412697,19.7523919 L2.34126974,12.7482826 C1.91873689,12.3783493 1.88855597,11.741464 2.25072699,11.3333947 L2.34126974,11.2435123 L10.3318825,4.24762166 C10.7474133,3.88381873 11.3791881,3.92575249 11.7429911,4.34128332 Z"
                    id="Combined-Shape"
                  />
                </g>
              </g>
            </svg>
            <div className={styles.forgot} style={{ color: theme.appThemeColor }}>
              Forgot Password
            </div>
          </div>
          <div className={styles.forgotText}>Please enter the email address associated with your account</div>
          <div className={`${styles.forgotInput} form-label-group`} id="Input_Email_Group">
            <ThemeInput
              id="email"
              label="Email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              error={!!(errors.email && touched.email)}
              helperText={touched.email ? errors.email : ''}
              type="email"
              placeholder="Email address"
            />
          </div>
          <div className={styles.forgotTextMessageContainer}>{forgotPasswordText}</div>
          <div className={styles.forgotSubmit}>
            <div className={styles.signinButtonWrapper}>
              <button
                style={{ backgroundColor: theme.appThemeColor }}
                className={classNames(
                  styles.noOutline,
                  styles.signInBtn,
                  'btn',
                  'btn-lg',
                  'btn-block',
                  'text-white',
                  'd-flex',
                  'align-items-center',
                  'justify-content-center',
                )}
                type="submit"
                disabled={Object.keys(errors).length > 0 || isSubmitInProgress}
              >
                {isSubmitInProgress ? (
                  <CircularProgress className={styles.progress} size="sm" role="status" aria-hidden="true" />
                ) : (
                  'Submit'
                )}
              </button>
            </div>
          </div>
          <div className={styles.signInErrorMessage}>
            <div className="warning-img-wrapper">
              <div className="warning-img" />
            </div>
            <div />
          </div>
        </form>
      )}
    </Formik>
  )

  if (!ready) return null

  return (
    <ThemeContext.Consumer>
      {(theme: any) => (
        <div style={{ backgroundColor: '#f7f7f7' }}>
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className={`col-md-6 col-md-offset-3, ${styles.signInWrapper}`}>
                <div className={`card ${styles.cardContainer}`}>
                  <div className={`${styles.cardBody} ${isForgotPassword ? styles.forgotClass : ''} text-center`}>
                    {isForgotPassword ? renderForgotPasswordForm(theme) : renderForm(theme)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </ThemeContext.Consumer>
  )
}
