import { createSlice } from '@reduxjs/toolkit'
import api from '../api'
import { AppThunk, RootState } from '../app/store'
import { convertArrayBufferToBlobUrl } from '../libs/utils'

export type User = {
  id: string
  superhero: boolean
  email: string
  accountId: string
  nationalHealthId: string
  tenantId: string
  firstName: string
  lastName: string
  middleName: string
  birthDate: string
  birthPlace: string
  gender: string
  contact: {
    mobileCountryCode: string
    mobileNumber: string
    faxCode: string
    mobileNumberverified: boolean
    homeCountryCode: string
    homeNumber: string
    workCountryCode: string
    workNumber: null
  }
  settings: {
    firstTimeLogin: boolean
    acceptMessageNotification: boolean
    acceptEmailNotification: boolean
    acceptSMSNotification: boolean
    badgeSharingEnabled: boolean
    biometricEnabled: null
  }
  primaryAddress: {
    line1: string
    line2: string
    city: string
    state: string
    zip: string
    country: string
  }
  emergencyContact: string
  emailStatus: string
  timezone: string
  billingAddress: string
  shippingAddress: string
  metadata: {
    'User Segment': string
    _auth0_user_id: string
    fingerprint: string
  }
  created: string
  creator: string
  modified: string
  modifier: string
}

const initialState: User = null

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setProfileData: (state, { payload }) => payload,
  },
})

export const { setProfileData } = userSlice.actions

export const setProfile = (): AppThunk => async (dispatch) => {
  const [result] = await api.getProfile()
  dispatch(setProfileData(result))
}

export const getUserCode = (email): AppThunk => async (): Promise<string> => {
  const [result] = await api.getUserCode({ id: email, width: 150 })
  if (result) {
    return convertArrayBufferToBlobUrl(result, 'image/png')
  }
}

export const confirmFirstLogin = (): AppThunk => async (dispatch, getState) => {
  const { user } = getState()
  const [res, err] = await api.updateAccount({
    ...user,
    settings: {
      ...user.settings,
      firstTimeLogin: false,
    },
  })
  if (err) return err
  dispatch(setProfileData(res))
}

export const resetPassword = (user: { id: string; password: string }): AppThunk => async (dispatch) => {
  const [passRes, passErr] = await api.resetPassword(user)
  if (passErr) return passErr.response.data.message
  if (passRes && passRes.success) {
    await dispatch(confirmFirstLogin())
    return ''
  }
}

export const selectProfile = (state: RootState) => state.user
export const selectFirstLogin = (state: RootState) => {
  if (state.user) return state.user.settings.firstTimeLogin
  return false
}
export const selectIsIFSUserSegment = (state: RootState): boolean => {
  if (!(state.user && state.user.metadata)) return false
  const { metadata } = state.user
  return !!(metadata['User Segment'] && /IFS/gi.test(metadata['User Segment']))
}
export default userSlice.reducer
