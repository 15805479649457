import classNames from 'classnames'
import _ from 'lodash'
import React, { useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
// eslint-disable-next-line import/extensions
import { version } from '../../package.json'
import HelpCenterIcon from '../assets/help-center.svg'
import HomeIcon from '../assets/ic_home.svg'
import MenuIcon from '../assets/ic_menu.svg'
import LegalIcon from '../assets/legal.svg'
import LogoutIcon from '../assets/logout.svg'
import ResourcesIcon from '../assets/resources.svg'
import SettingsIcon from '../assets/settings.svg'
import TestHistoryIcon from '../assets/test-history.svg'
import userImage from '../assets/user.png'
import { ROUTES } from '../constatants'
import { ThemeContext } from '../features/auth0'
import { doLogout } from '../features/deviceSlice'
import { selectPhotoUrl } from '../features/userPhotoSlice'
import { selectProfile } from '../features/userSlice'
import { getTenantName, useOnClickOutside } from '../libs/helpers'
import styles from './Header.module.css'

interface Props {
  history: any
  disableButtons?: boolean
}

export function Header({ history, disableButtons }: Props) {
  const dispatch = useDispatch()
  const { firstName, lastName } = useSelector(selectProfile)
  const userPhotoUrl = useSelector(selectPhotoUrl)
  const toggleMenuButtonRef = useRef<HTMLButtonElement>(null)
  const drawerRef = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [signoutModalState, setSignoutModalState] = useState(false)
  const { REACT_APP_BUILD_NUMBER } = process.env

  useOnClickOutside(drawerRef, (e: React.MouseEvent) => {
    if (toggleMenuButtonRef.current && toggleMenuButtonRef.current.contains(e.target as Node)) return
    if (!isOpen) return
    setIsOpen(false)
  })

  const goTo = (route) => {
    if (disableButtons) return null

    setIsOpen(false)
    history.push(route)
  }

  const goToDashboard = () => goTo(ROUTES.DASHBOARD)
  const goToSelfAssessmentHistory = () => goTo(ROUTES.SELF_ASSESSMENT_HISTORY)
  const goToResources = () => goTo(ROUTES.RESOURCES)
  const goToSettings = () => goTo(ROUTES.SETTINGS)
  const goToTerms = () => goTo(ROUTES.TERMS)
  const goToHelp = () => goTo(ROUTES.HELP)
  const goToHistory = () => goTo(ROUTES.TEST_HISTORY)

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  const handleLogout = () => {
    dispatch(doLogout())
  }

  const handleCloseModal = () => {
    setSignoutModalState(false)
  }

  return (
    <ThemeContext.Consumer>
      {(theme: any) => (
        <div id="nav-container" className={styles.navContainer}>
          <nav className="navbar navbar-expand-lg navbar-light bg-white py-0 pl-2">
            <div className="w-100">
              <div className="d-flex">
                <button
                  className={classNames('navbar-toggler border-0', {
                    [styles.disableButtons]: disableButtons,
                    [styles.headerMenuIconActive]: isOpen,
                    [styles.headerMenuIcon]: true,
                  })}
                  type="button"
                  data-toggle="collapse"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  ref={toggleMenuButtonRef}
                  onClick={toggleMenu}
                  disabled={disableButtons}
                >
                  <img alt={'Menu'} src={MenuIcon} />
                </button>

                <div className="nav-item middle-text-title" id="nav-title-image">
                  <div className="position-relative" id="nav-dashboard-image">
                    <span style={{ cursor: 'pointer' }} onClick={goToDashboard}>
                      <img
                        style={{ width: 'auto', padding: 10 }}
                        src={theme.networkLogoInThemeColor}
                        className={styles.logoImage}
                        alt="logo"
                      />
                    </span>
                    <div className="active-provider position-absolute rounded-circle d-none" />
                    <div className="inactive-provider rounded-circle position-absolute d-none" />
                    <div className="not-online rounded-circle position-absolute d-none" />
                  </div>
                </div>
                {/* <div className={`${styles.notificationWrapper} position-relative ml-auto`}> */}
                {/*  <svg */}
                {/*    width="24px" */}
                {/*    height="24px" */}
                {/*    viewBox="0 0 24 24" */}
                {/*    version="1.1" */}
                {/*    xmlns="http://www.w3.org/2000/svg" */}
                {/*    xmlnsXlink="http://www.w3.org/1999/xlink" */}
                {/*  > */}
                {/*    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"> */}
                {/*      <g id="icon/color/message" fill={theme.appThemeColor}> */}
                {/*        <path */}
                {/*          d="M12,1 C18.074908,1 23,5.92015801 23,11.9899819 C23,17.9452854 18.2522852,22.8125293 12.2936152,22.9760319 L12.2936152,22.9760319 L12.207,22.977 L12.1166211,22.9932723 L12,23 L2,23 C1.10885783,23 0.662798593,21.9223678 1.29324132,21.2925453 L1.29324132,21.2925453 L3.555,19.033 L3.34781639,18.7775231 C1.9113848,16.9530302 1.0780023,14.7086018 1.00520809,12.3305776 L1.00520809,12.3305776 L1,11.9899819 C1,5.92015801 5.925092,1 12,1 Z M12,3 C7.029213,3 3,7.0251765 3,11.9899819 C3,14.4118466 3.9618376,16.6790417 5.64469813,18.3554968 C6.03663024,18.7459371 6.037079,19.3804109 5.64569961,19.7714053 L5.64569961,19.7714053 L4.415,21 L11.807,21 L11.8708658,20.9882457 L11.9862897,20.9800578 L12.2524599,20.9765026 C17.1157565,20.8429794 21,16.8609457 21,11.9899819 C21,7.0251765 16.970787,3 12,3 Z M14.6923077,10.4615385 C15.3295593,10.4615385 15.8461538,10.978133 15.8461538,11.6153846 C15.8461538,12.2526362 15.3295593,12.7692308 14.6923077,12.7692308 C14.0550561,12.7692308 13.5384615,12.2526362 13.5384615,11.6153846 C13.5384615,10.978133 14.0550561,10.4615385 14.6923077,10.4615385 Z M11.6153846,10.4615385 C12.2526362,10.4615385 12.7692308,10.978133 12.7692308,11.6153846 C12.7692308,12.2526362 12.2526362,12.7692308 11.6153846,12.7692308 C10.978133,12.7692308 10.4615385,12.2526362 10.4615385,11.6153846 C10.4615385,10.978133 10.978133,10.4615385 11.6153846,10.4615385 Z M8.53846154,10.4615385 C9.17571317,10.4615385 9.69230769,10.978133 9.69230769,11.6153846 C9.69230769,12.2526362 9.17571317,12.7692308 8.53846154,12.7692308 C7.9012099,12.7692308 7.38461538,12.2526362 7.38461538,11.6153846 C7.38461538,10.978133 7.9012099,10.4615385 8.53846154,10.4615385 Z" */}
                {/*          id="Combined-Shape" */}
                {/*        /> */}
                {/*      </g> */}
                {/*    </g> */}
                {/*  </svg> */}
                {/*  <div */}
                {/*    className={`${styles.numberWrapper} rounded-circle`} */}
                {/*    style={{ backgroundColor: theme.appThemeColor }} */}
                {/*  > */}
                {/*    2 */}
                {/*  </div> */}
                {/* </div> */}
                <div className={`${styles.userWrapper} d-flex align-items-center ml-auto`}>
                  <div>
                    <img
                      src={userPhotoUrl || userImage}
                      className={`${styles.uploadingImage} rounded-circle`}
                      alt={_.join([firstName, lastName], ' ')}
                      data-toggle="modal"
                      data-target="#uploadImage"
                    />
                  </div>
                  <div className={styles.flName}>{_.join([firstName, lastName], ' ')}</div>
                </div>
              </div>
            </div>
          </nav>

          <div
            className={`${isOpen ? styles.navButtonEnabled : styles.navButtonDisabled} ${styles.dropdownEnabled} ${
              styles.dropdownOpened
            } show collapse navbar-collapse justify-content-center`}
            id="navbarSupportedContent"
            ref={drawerRef}
          >
            <div />
            <ul className={`navbar-nav ${styles.navBarContainer}`}>
              <li className="nav-item d-block pt-4 cursor-pointer">
                <div className="d-flex align-items-center" onClick={goToDashboard}>
                  <img src={HomeIcon} className={styles.headerIcon} alt="resources" />
                  <div className={`${styles.headerMenuLink} nav-link text-capitalize p-2`}>Home</div>
                </div>
              </li>
              {getTenantName() === 'delta' && (
                <li className="nav-item d-block cursor-pointer">
                  <div className="d-flex align-items-center" onClick={goToSelfAssessmentHistory}>
                    <img src={TestHistoryIcon} className={styles.headerIcon} alt="self-assessment-history" />
                    <div className={`${styles.headerMenuLink} nav-link text-capitalize p-2`}>Self-Assessment History</div>
                  </div>
                </li>
              )}
              <li className="nav-item d-block cursor-pointer">
                <div className="d-flex align-items-center" onClick={goToResources}>
                  <img src={ResourcesIcon} className={styles.headerIcon} alt="resources" />
                  <div className={`${styles.headerMenuLink} nav-link text-capitalize p-2`}>Testing Resources</div>
                </div>
              </li>
              {getTenantName() !== 'delta' && (
                <li className="nav-item d-block cursor-pointer">
                  <div className="d-flex align-items-center" onClick={goToHistory}>
                    <img src={TestHistoryIcon} className={styles.headerIcon} alt="test-history" />
                    <div className={`${styles.headerMenuLink} nav-link text-capitalize p-2`}>Test History</div>
                  </div>
                </li>
              )}
              <li className="nav-item d-block cursor-pointer">
                <div className="d-flex align-items-center" onClick={goToHelp}>
                  <img src={HelpCenterIcon} className={styles.headerIcon} alt="help-center" />
                  <div className={`${styles.headerMenuLink} nav-link text-capitalize p-2`}>FAQs</div>
                </div>
              </li>
              <li className="nav-item d-block cursor-pointer">
                <div className="d-flex align-items-center" onClick={goToSettings}>
                  <img src={SettingsIcon} className={styles.headerIcon} alt="settings" />
                  <div className={`${styles.headerMenuLink} nav-link text-capitalize p-2`}>Settings</div>
                </div>
              </li>
              {/* <li className="nav-item d-block cursor-pointer"> */}
              {/*  <div className="d-flex align-items-center"> */}
              {/*    <img src={SupoortIcon} className={styles.headerIcon} alt="support-image" /> */}
              {/*    <div className={`${styles.headerMenuLink} nav-link p-2`}>Contact Support</div> */}
              {/*  </div> */}
              {/* </li> */}
              <li className="nav-item d-block cursor-pointer">
                <div className="d-flex align-items-center" onClick={goToTerms}>
                  <img src={LegalIcon} className={styles.headerIcon} alt="legal" />
                  <div className={`${styles.headerMenuLink} nav-link text-capitalize p-2`}>Legal</div>
                </div>
              </li>
              <li className="nav-item d-block cursor-pointer">
                <div className="d-flex align-items-center" onClick={() => setSignoutModalState(true)}>
                  <img src={LogoutIcon} className={styles.headerIcon} alt="logout" />
                  <div className={`${styles.headerMenuLink} nav-link text-capitalize p-2`}>Logout</div>
                </div>
              </li>
            </ul>
            <div className={styles.versionText}>
              {`${version}${REACT_APP_BUILD_NUMBER ? `.${REACT_APP_BUILD_NUMBER}` : ''}`}
            </div>
          </div>

          <Modal show={signoutModalState} centered className="modal-dialog-signout">
            <Modal.Header className="modal-dialog-header">
              <Modal.Title className="modal-dialog-title-bold">Care check</Modal.Title>

              <Modal.Title>Are you sure you want to sign out?</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-dialog-body">
              <div style={{ cursor: 'pointer' }} onClick={handleCloseModal}>
                No
              </div>
              <div style={{ cursor: 'pointer' }} onClick={handleLogout}>
                Yes
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </ThemeContext.Consumer>
  )
}
