import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { renderRoutes } from 'react-router-config'
import { ROUTES } from '../constatants'
import { selectConsent, getConsent } from '../features/consentSlice'
import { updateDevice } from '../features/deviceSlice'
import { getProfilePhoto } from '../features/userPhotoSlice'
import { selectProfile, setProfile } from '../features/userSlice'
import { getAuthClient } from '../libs/auth0'
import { createMessaging } from '../libs/firebase'
import { IncomingCallModal } from '../pages/video-calls/IncomingCallModal'
import { Loading } from '../ui'
import { Header } from './Header'

export function Layout({ route, history, location }: RouteProps) {
  const profile = useSelector(selectProfile)
  const [incomingCall, setIncomingCall] = useState(null)
  const [consentApproved, setConsentApproved] = useState(false)
  const consents = useSelector(selectConsent)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setProfile())
    dispatch(getProfilePhoto())
    dispatch(getConsent())
    const messaging = createMessaging()
    ;(async function () {
      let token = ''
      let user = null

      if (messaging) {
        try {
          token = await messaging.getToken()
          console.log('FCM token', token)
        } catch (e) {
          console.log('Unable to get permission to notify.', e)
        }
      }

      try {
        user = await (() => {
          return new Promise((resolve, reject) => {
            getAuthClient().client.userInfo(localStorage.getItem('access_token'), (err, result) => {
              if (err) return reject(err)
              resolve(result)
            })
          })
        })()
      } catch (e) {
        console.log('User info not found', e)
      }

      let userId = ''
      if (user && user.email) {
        userId = user.email
        localStorage.setItem('user_id', userId)
      }

      dispatch(
        updateDevice({
          deviceToken: token,
          isLoggedIn: true,
          userId,
        }),
      )
    })()
    function onMessage(message) {
      console.log('Notification message', message)
      if (location.pathname === ROUTES.VIDEO) return

      let data =
        _.get(message, 'data.firebase-messaging-msg-data.data.body', null) || _.get(message, 'data.data.body', null)
      if (!data) return
      try {
        const body = JSON.parse(data)
        setIncomingCall(body)
      } catch (e) {
        console.log('Parse body error', e)
      }
    }

    if (messaging) {
      navigator.serviceWorker.addEventListener('message', onMessage)
    }

    return () => {
      if (messaging) {
        navigator.serviceWorker.removeEventListener('message', onMessage)
      }
    }
  }, [])

  useEffect(() => {
    const docs = ['TermsOfService', 'PrivacyPolicy', 'NNPBooklet', 'Consent', 'ServiceAgreement']
    if (consents) {
      let consented = true
      docs.forEach((docKey) => {
        consented = consents.items.find((v) => v.key === docKey && v.consented && !v.archived)
      })

      if (!consented) {
        history.push(ROUTES.CONSENT)
      }
    }

    setConsentApproved(true)
  }, [consents])

  const closeIncomingCallModal = () => {
    setIncomingCall(null)
  }

  if (!(profile && consentApproved)) return <Loading />

  return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <Header
        disableButtons={location.pathname === ROUTES.CONSENT || location.pathname === ROUTES.NEW_PASSWORD}
        history={history}
      />
      {renderRoutes(route.routes)}
      <IncomingCallModal history={history} data={incomingCall} onClose={closeIncomingCallModal} />
    </div>
  )
}
