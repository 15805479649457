import classNames from 'classnames'
import React from 'react'
import classes from './classes.module.css'

type IconButtonProps = {
  onClick?: (e: React.MouseEvent) => void
  onMouseDown?: (e: React.MouseEvent) => void
  type?: 'button' | 'submit' | 'reset'
  className?: string
}
export const IconButton: React.FC<IconButtonProps> = ({
  onClick,
  onMouseDown,
  children,
  type = 'button',
  className,
}) => {
  return (
    <button className={classNames(classes.root, className)} onClick={onClick} onMouseDown={onMouseDown} type={type}>
      <span className={classes.span}>{children}</span>
    </button>
  )
}
