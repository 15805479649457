import React from 'react'
import styles from './Privacy.module.css'

export function Privacy() {
  return (
    <>
      <div className={`${styles.fullSettingsFrame} container-fluid mb-5 mt-3`}>
        <div className="row">
          <div className={`${styles.providerAppHolder} col-md-12`}>
            <div className={`${styles.termsTitle} text-center color-safe mb-1`}>Privacy Policy</div>
            <div className={`${styles.importantText} text-uppercase color-safe font-weight-bold pt-3 mb-1`}>
              Important
            </div>
            <p className="terms-text mb-4">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed cursus odio ac volutpat ullamcorper. Praesent
              tempus cursus est, sed maximus mauris maximus non. Proin sed pulvinar justo, ut vestibulum arcu.
              Pellentesque sit amet nisl eget ipsum pulvinar suscipit. Nullam sodales interdum urna. Nulla nisi nibh,
              pellentesque vitae posuere vitae, ultrices sit amet eros. Sed condimentum at quam nec blandit. In id nisl
              lacinia, dictum ligula vitae, venenatis risus. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse rutrum urna justo, a ornare neque dapibus quis. Etiam fermentum, turpis sollicitudin faucibus
              bibendum, metus massa placerat sapien, eget aliquam urna quam eu nibh. Mauris luctus maximus cursus. Etiam
              rutrum magna quis purus vehicula volutpat.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
