import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import api from '../../api'
import { AppThunk, RootState } from '../../app/store'
import { selectIsIFSUserSegment } from '../../features/userSlice'
import { getTenantName } from '../../libs/helpers'
import { countriesQuestion } from './FakeQuestions/countries'

export interface CheckerState {
  questionnaire: any
  currentQuestion: any
  previousQuestion: any
  questionsQueue: any[]
  nbOfQuestions: number
  outcome: any
  currentQuestionNumber?: number
  isFakeQuestion: boolean
  nextAfterFakeQuestion: any
  prevBeforeFakeQuestion: any
}

const initialState: CheckerState = {
  currentQuestion: null,
  outcome: null,
  previousQuestion: null,
  questionnaire: null,
  questionsQueue: [],
  nbOfQuestions: 0,
  currentQuestionNumber: 1,
  isFakeQuestion: false,
  nextAfterFakeQuestion: null,
  prevBeforeFakeQuestion: null,
}

export const checkerSlice = createSlice({
  name: 'checker',
  initialState,
  reducers: {
    setChecker: (state, { payload }) => ({
      ...state,
      ...payload,
      questionsQueue: state.questionsQueue,
      isFakeQuestion: false,
    }),
    setFakeQuestion: (state, { payload }) => ({
      ...state,
      prevBeforeFakeQuestion: payload.prevBeforeFakeQuestion,
      nextAfterFakeQuestion: payload.nextAfterFakeQuestion,
      currentQuestion: payload.fakeQuestion,
      isFakeQuestion: true,
    }),
    resetFakeQuestion: (state) => ({
      ...state,
      isFakeQuestion: false,
    }),
    setSymptomChecker: (state, action) => {
      state.questionnaire.symptomChecker = _.extend(state.questionnaire.symptomChecker, action.payload)
    },
    resetSymptomChecker: () => initialState,
    addPreviousQuestion: (state, { payload: newQuestion }) => ({
      ...state,
      questionsQueue: [...state.questionsQueue, newQuestion],
    }),
    removePrevQuestion: (state, { payload }) => ({
      ...state,
      ...payload,
      questionsQueue: state.questionsQueue.slice(0, state.questionsQueue.length - 1),
    }),
    setQuestionNumber: (state, { payload }) => ({
      ...state,
      currentQuestionNumber: payload,
    }),
  },
})

export const {
  setChecker,
  setSymptomChecker,
  resetSymptomChecker,
  addPreviousQuestion,
  removePrevQuestion,
  setFakeQuestion,
  setQuestionNumber,
  resetFakeQuestion,
} = checkerSlice.actions

export const startChecker = (): AppThunk => async (dispatch) => {
  const [result] = await api.startChecker()
  dispatch(setQuestionNumber(initialState.currentQuestionNumber))
  dispatch(setChecker(result))
}

const checkFakeQuestion = (state, nextQuestionNumber): boolean => {
  const { questionnaire: currentQuestionnaire } = state.checker
  if (!currentQuestionnaire) return

  const isIFSUserSegment = selectIsIFSUserSegment(state)
  const { symptomChecker } = currentQuestionnaire
  const { symptoms } = symptomChecker

  return !!(
    getTenantName() === 'delta' &&
    isIFSUserSegment &&
    nextQuestionNumber === 2 &&
    _.includes(symptoms, 'FEVER')
  )
}

export const nextQuestionnaireQuestion = (id, data): AppThunk => async (dispatch, getState) => {
  const checkerState = getState().checker
  const { currentQuestionNumber } = checkerState
  const nextQuestionNumber = currentQuestionNumber + 1
  const isFakeQuestionNext = checkFakeQuestion(getState(), nextQuestionNumber)
  dispatch(setQuestionNumber(nextQuestionNumber))

  if (isFakeQuestionNext) {
    dispatch(
      setFakeQuestion({
        fakeQuestion: countriesQuestion,
        prevBeforeFakeQuestion: checkerState,
      }),
    )
  } else {
    const [result] = await api.nextQuestion(id, data)
    dispatch(setChecker(result))
  }
}

export const goToPrevQuestion = (): AppThunk => async (dispatch, getState) => {
  const checkerState = getState().checker
  const { isFakeQuestion, prevBeforeFakeQuestion, questionnaire, currentQuestion } = checkerState

  const nextQuestionNumber = checkerState.currentQuestionNumber - 1
  const isFakeQuestionNext = checkFakeQuestion(getState(), nextQuestionNumber)
  dispatch(setQuestionNumber(nextQuestionNumber))

  function resetCurrentValue() {
    dispatch(
      setSymptomChecker({
        ...questionnaire.symptomChecker,
        [currentQuestion.name]: null,
      }),
    )
  }

  if (isFakeQuestion) {
    dispatch(setChecker(prevBeforeFakeQuestion))
    resetCurrentValue()
    return
  }

  const [result] = await api.prevQuestion(questionnaire.id)
  if (isFakeQuestionNext) {
    dispatch(
      setFakeQuestion({
        fakeQuestion: countriesQuestion,
        nextAfterFakeQuestion: checkerState,
        prevBeforeFakeQuestion: result,
      }),
    )
  } else {
    dispatch(setChecker(result))
  }

  resetCurrentValue()
}

export const setQuestionResult = (key, result): AppThunk => async (dispatch) => {
  dispatch(setSymptomChecker({ [key]: result }))
}

export const resetChecker = (): AppThunk => async (dispatch) => {
  dispatch(resetSymptomChecker())
}

export const selectQuestionnaire = (state: RootState) => state.checker

export default checkerSlice.reducer
