import { createSlice } from '@reduxjs/toolkit'
import api from '../api'
import { AppThunk, RootState } from '../app/store'
import { getTenantName } from '../libs/helpers'

const initialState = {
  details: null,
  isLoading: true,
}

export const faqSlice = createSlice({
  name: 'faq',
  initialState,
  reducers: {
    setLoading: (state, { payload: { isLoading } }) => ({
      ...state,
      isLoading,
    }),
    setFaq: (state, { payload }) => ({
      ...state,
      details: payload,
      isLoading: false,
    }),
  },
})

export const { setFaq, setLoading } = faqSlice.actions

export const getFaq = (): AppThunk => async (dispatch, getState) => {
  dispatch(setLoading({ isLoading: true }))
  let tenant = getTenantName()
  const [result] = await api.getFaq(tenant)
  dispatch(setFaq(result))
}

export const selectFaq = (state: RootState) => state.faq

export default faqSlice.reducer
