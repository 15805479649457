import { createSlice } from '@reduxjs/toolkit'
import api from '../api'
import { AppThunk, RootState } from '../app/store'
import { getTenantName } from '../libs/helpers'

const initialState = null

export const tenantConfigSlice = createSlice({
  name: 'tenantConfig',
  initialState,
  reducers: {
    setTenantConfig: (state, action) => {
      return action.payload
    },
  },
})

export const { setTenantConfig } = tenantConfigSlice.actions

export const getTenantConfig = (): AppThunk => async (dispatch) => {
  let tenant = getTenantName()

  const [result, error] = await api.getTenantConfig(tenant)
  if (result) dispatch(setTenantConfig(result.data))
}

export const selectTenantConfig = (state: RootState) => state.tenantConfig

export default tenantConfigSlice.reducer
