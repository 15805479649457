import _ from 'lodash'
import { OTPublisher, OTSubscriber, preloadScript, createSession } from 'opentok-react'
import React, { useEffect, useState } from 'react'
import { ROUTES } from '../../constatants'
import { buildEnv } from '../../libs/helpers'
import { Loading } from '../../ui'
import styles from './Video.module.css'

const Video = ({ location, history }: RouteProps) => {
  let publisher = null

  const [session, setSession] = useState(null)
  const [unMuted, setUnmute] = useState(true)
  const [streams, setStreams] = useState([])

  const publisherDefaultConfig = {
    width: '100%',
    height: '100%',
    showControls: false,
    publishAudio: unMuted,
    publishVideo: true,
  }

  const subscriberDefaultConfig = {
    width: '100%',
    height: '100%',
    showControls: false,
  }

  useEffect(() => {
    const search = new URLSearchParams(location.search)

    const sessionHelper = createSession({
      apiKey: process.env[buildEnv('TOKBOX_API_KEY')],
      sessionId: search.get('sessionId'),
      token: search.get('token'),
      onStreamsUpdated: (str) => {
        setStreams(str)
      },
    })

    setSession(sessionHelper)

    return () => sessionHelper.disconnect()
  }, [])

  const onMute = () => {
    setUnmute(!unMuted)
    publisher.state.publisher.publishAudio(!unMuted)
  }

  const onFlip = () => {
    publisher.state.publisher.cycleVideo()
  }

  if (!session) return <Loading />

  return (
    <>
      <div
        className={`${styles.fullSettingsFrame} ${styles.flexGrow} container-fluid profile-wrapper mt-0 mb-0 d-flex`}
      >
        <div className={`${styles.flexGrow} row`}>
          <div
            className={`${styles.providerAppHolder} col-md-12 provider-app-holder text-center p-0 d-flex flex-column justify-content-end`}
          >
            <div className={styles.videoContainer}>
              <div className={styles.subscriber}>
                {_.map(streams, (stream) => {
                  return (
                    <OTSubscriber
                      key={stream.id}
                      stream={stream}
                      session={session.session}
                      properties={subscriberDefaultConfig}
                    />
                  )
                })}
              </div>
              <div className={styles.publisher}>
                <OTPublisher
                  ref={(ref) => {
                    publisher = ref
                  }}
                  properties={publisherDefaultConfig}
                  session={session.session}
                />
              </div>
            </div>
            <div className={`${styles.callActions} d-flex justify-content-center align-items-center position-relative`}>
              <div className={`${styles.callActionsInner} text-white d-flex justify-content-between`}>
                {/* <div className="d-flex flex-column align-items-center"> */}
                {/*  <i */}
                {/*    className={`${styles.caIcon} fa fa-volume-up rounded-circle d-flex align-items-center justify-content-center`} */}
                {/*    aria-hidden="true" */}
                {/*  /> */}
                {/*  <span className="small">speaker</span> */}
                {/* </div> */}
                <div onClick={onMute} className="d-flex flex-column align-items-center">
                  <i
                    className={`${styles.caIcon} fa ${
                      unMuted ? 'fa-microphone' : 'fa-microphone-slash'
                    }  rounded-circle d-flex align-items-center justify-content-center`}
                    aria-hidden="true"
                  />
                  <span className="small">{unMuted ? 'mute' : 'unmute'}</span>
                </div>
                <div onClick={onFlip} className="d-flex flex-column align-items-center">
                  <i
                    className={`${styles.caIcon} fa fa-refresh rounded-circle d-flex align-items-center justify-content-center`}
                    aria-hidden="true"
                  />
                  <span className="small">flip</span>
                </div>
                <div
                  onClick={() => {
                    history.push(ROUTES.DASHBOARD)
                  }}
                  className="d-flex flex-column align-items-center"
                >
                  <i
                    className={`${styles.caIcon} ${styles.caEnd} fa fa-times rounded-circle d-flex align-items-center justify-content-center`}
                    aria-hidden="true"
                  />
                  <span className="small">end</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default preloadScript(Video)
