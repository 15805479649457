import React from 'react'
import { Link } from 'react-router-dom'
import arrowIcon from '../../assets/arrow-icon.svg'
import { ROUTES } from '../../constatants'
import { ThemeContext, useIsFirstLogin } from '../../features/auth0'
import styles from './Settings.module.css'

export function Settings() {
  useIsFirstLogin()

  return (
    <ThemeContext.Consumer>
      {(theme: any) => (
        <>
          <div className={`${styles.fullSettingsFrame} container page-content mb-5 pt-0 mt-3`}>
            <div className="row">
              <div className="col-md-12">
                <div className={`${styles.titleArea} text-uppercase px-2`}>Active tenant</div>
                <div className="pl-3 bg-white border-radius-10">
                  <div
                    className={`${styles.pageLinkWrapper} color-safe d-flex justify-content-between align-items-center border-0`}
                  >
                    <div className={`${styles.pageLinkText}`}>{theme.name}</div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className={`${styles.titleArea} text-uppercase px-2`}>Account</div>
                <div className="pl-3 bg-white border-radius-10">
                  <Link
                    to={ROUTES.EDIT_ACCOUNT}
                    className={`${styles.pageLinkWrapper} color-safe d-flex justify-content-between align-items-center border-0`}
                  >
                    <div className={`${styles.pageLinkText}`}>Edit account</div>
                    <img
                      src={arrowIcon}
                      alt="arrow-icon"
                      className={`${styles.arrowIcon} mr-3 ml-auto d-flex align-items-center`}
                    />
                  </Link>
                </div>
              </div>
              <div className="col-md-12">
                <div className={`${styles.titleArea} text-uppercase px-2`}>Support</div>
                <div className="pl-3 bg-white border-radius-10">
                  <Link
                    to={ROUTES.HELP}
                    className={`${styles.pageLinkWrapper} color-safe d-flex justify-content-between align-items-center border-0`}
                  >
                    <div className={`${styles.pageLinkText}`}>Help Center</div>
                    <img
                      src={arrowIcon}
                      alt="arrow-icon"
                      className={`${styles.arrowIcon} mr-3 ml-auto d-flex align-items-center`}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </ThemeContext.Consumer>
  )
}
