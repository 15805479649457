import React, { useEffect } from 'react'
import { renderRoutes } from 'react-router-config'
import { BrowserRouter } from 'react-router-dom'
import 'react-phone-input-2/lib/style.css'
import './App.css'

interface Props {
  routes: any
}

function App({ routes }: Props) {
  useEffect(() => {
    window.alertify.set('notifier', 'position', 'top-center')
  }, [])

  return (
    <>
      <BrowserRouter>{renderRoutes(routes)}</BrowserRouter>
      <div style={{ display: 'none' }} id="notifier" />
    </>
  )
}

export default App
