import React from 'react'

export function Loading() {
  return (
    <div className="loader-container">
      <div className="lds-roller">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  )
}
