import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import configSlice from '../features/configSlice'
import consentSlice from '../features/consentSlice'
import counterReducer from '../features/counter/counterSlice'
import deviceSlice from '../features/deviceSlice'
import faqSlice from '../features/faqSlice'
import latestResultSlice from '../features/latestResultSlice'
import resourcesSlice from '../features/resourcesSlice'
import tenantConfigSlice from '../features/tenantConfigSlice'
import { testHistorySlice } from '../features/test-history'
import userPhotoSlice from '../features/userPhotoSlice'
import userSlice from '../features/userSlice'
import verbiageSlice from '../features/verbiageSlice'
import checkerReducer from '../pages/checker/checkerSlice'
import assessmentHistorySlice from '../pages/self-assessment-history/assessmentHistorySlice'
import history from './history'
import locationReducer, { updateLocation, updatePrevPath } from './location'

export const store = configureStore({
  reducer: {
    assessmentHistory: assessmentHistorySlice,
    location: locationReducer,
    counter: counterReducer,
    checker: checkerReducer,
    config: configSlice,
    tenantConfig: tenantConfigSlice,
    user: userSlice,
    userPhotoUrl: userPhotoSlice,
    resources: resourcesSlice,
    device: deviceSlice,
    verbiage: verbiageSlice,
    consent: consentSlice,
    latestResult: latestResultSlice,
    faq: faqSlice,
    testHistory: testHistorySlice.reducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
})

history.listen(({ location }) => {
  const state = store.getState()

  updatePrevPath(store)(state.location.pathname)
  updateLocation(store)(location)
})

export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
