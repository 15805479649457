import { createSlice } from '@reduxjs/toolkit'
import api from '../api'
import { AppThunk, RootState } from '../app/store'

const initialState = {
  list: [],
  primary: null,
}

export const resourcesSlice = createSlice({
  name: 'resources',
  initialState,
  reducers: {
    setResources: (state, action) => {
      state.list = action.payload
      return state
    },
    setPrimaryResource: (state, action) => {
      state.primary = action.payload
      return state
    },
  },
})

export const { setResources } = resourcesSlice.actions

export const getResources = (): AppThunk => async (dispatch) => {
  const [result, error] = await api.getResourcesList()
  if (result) dispatch(setResources(result))
}

export const getPrimaryResource = (): AppThunk => async (dispatch) => {
  const [result, error] = await api.getResourcePrimaryVideo()
  if (result) dispatch(setResources(result))
}

export const selectResourcesList = (state: RootState) => state.resources.list
export const selectPrimaryResources = (state: RootState) => state.resources.primary

export default resourcesSlice.reducer
