import { DispatchProp, DefaultRootState } from 'react-redux'
import history from './history'

// ------------------------------------
// Constants
// ------------------------------------
export const LOCATION_CHANGE = 'LOCATION_CHANGE'
export const SET_PREV_PATH = 'SET_PREV_PATH'

// ------------------------------------
// Actions
// ------------------------------------
export function locationChange(location = '/') {
  return {
    type: LOCATION_CHANGE,
    payload: location,
  }
}

export const setPrevPath = (prevPath = '') => {
  return {
    type: SET_PREV_PATH,
    payload: {
      prevPath,
    },
  }
}

// ------------------------------------
// Specialized Action Creator
// ------------------------------------
export const updateLocation = ({ dispatch }: DispatchProp) => {
  return (nextLocation: any) => dispatch(locationChange(nextLocation))
}

export const updatePrevPath = ({ dispatch }: DispatchProp) => {
  return (nextLocation: string) => dispatch(setPrevPath(nextLocation))
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  ...history.location,
  prevPath: '',
}

const ACTION_HANDLERS = {
  [LOCATION_CHANGE]: (state: DefaultRootState, action: { payload: any; type: string }) => ({
    ...state,
    ...action.payload,
  }),
  [SET_PREV_PATH]: (state: DefaultRootState, action: { payload: any; type: string }) => ({
    ...state,
    ...action.payload,
  }),
}

export default function locationReducer(state = initialState, action: { payload: any; type: string }) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
