import qs from 'querystring'
import React, { useEffect, useState } from 'react'
import { renderRoutes } from 'react-router-config'
import { ROUTES } from '../constatants'
import { ThemeContext } from '../features/auth0'
import styles from '../pages/signin/SignIn.module.css'
import { Loading } from '../ui'

export function SignInLayout({ route, location }: RouteProps) {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (location.hash) {
      const params = qs.parse(location.hash.replace('#', ''))
      if (params.access_token && params.id_token) {
        localStorage.setItem('access_token', params.access_token as string)
        localStorage.setItem('id_token', params.id_token as string)

        window.location.href = ROUTES.DASHBOARD
      } else {
        setLoading(false)
      }
    } else {
      setLoading(false)
    }
  }, [])

  if (loading) return <Loading />

  return (
    <ThemeContext.Consumer>
      {() => (
        <div style={{ backgroundColor: '#f7f7f7', boxShadow: 'none' }} className={styles.headerWrapper}>
          {renderRoutes(route.routes)}
        </div>
      )}
    </ThemeContext.Consumer>
  )
}
