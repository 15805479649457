import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ThemeContext, useIsFirstLogin } from '../../features/auth0'
import { selectFaq, getFaq } from '../../features/faqSlice'
import { Loading } from '../../ui'
import styles from './Help.module.css'

export function Help() {
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext) as any
  const { details: faq, isLoading } = useSelector(selectFaq)
  const [activeSection, setActiveSection] = useState('')

  useEffect(() => {
    dispatch(getFaq())
  }, [])
  useIsFirstLogin()

  const renderChevronIcon = (key) => {
    return activeSection === key ? 'fa-chevron-up' : 'fa-chevron-down'
  }

  const changeActiveSection = (key) => {
    if (key === activeSection) return setActiveSection('')
    setActiveSection(key)
  }

  const renderItems = () => {
    if (!(faq && faq.items && faq.items.length)) return null

    return faq.items.map((i, index) => {
      return (
        <>
          <div
            onClick={() => {
              changeActiveSection(index)
            }}
            className={`${styles.pageLinkWrapper} ${styles.topBorder} d-flex justify-content-between`}
          >
            <div className="font-weight-bold" style={{ color: theme.appThemeColor }}>
              {i.question}
            </div>
            <i
              className={`fa ${renderChevronIcon(index)} ml-auto d-flex align-items-center`}
              style={{ color: theme.appThemeColor }}
            />
          </div>
          {activeSection === index ? (
            <div className="pt-3 pb-3">
              <div dangerouslySetInnerHTML={{ __html: i.answer }} />
            </div>
          ) : null}
        </>
      )
    })
  }

  if (isLoading) return <Loading />

  return (
    <>
      <div className={`${styles.fullSettingsFrame} container-fluid mb-5 mt-3`}>
        <div className="row">
          <div className={`${styles.providerAppHolder} col-md-12 pt-5 pb-5`}>
            {/*<input type="text" placeholder="Search" className={`${styles.helpSearch} pb-2 my-4 w-100 border-0`} />*/}
            {renderItems()}
          </div>
        </div>
      </div>
    </>
  )
}
