import { Layout } from '../../components/Layout'
import { SignInLayout } from '../../components/SignInLayout'
import { ROUTES } from '../../constatants'
import { Auth0 } from '../../features/auth0'
import { AccountInfoPage } from '../account-info/page'
import { Checker } from '../checker/Checker'
import { Consent } from '../consent/Consent'
import { Support } from '../contact-support/Support'
import { Dashboard } from '../dashboard/Dashboard'
import { EditAccountPage } from '../edit-account/page'
import { Help } from '../help-center/Help'
// import { Notices } from '../legal-notices/Notices'
import { Privacy } from '../privacy/Privacy'
import { Resource } from '../resource/Resource'
import { Resources } from '../resources/Resources'
import { RewardsProgram } from '../rewards-program/RewardsProgram'
import { SelfAssessmentHistory } from '../self-assessment-history/SelfAssessmentHistory'
import { Settings } from '../settings/Settings'
import { NewPassword } from '../setup-password/NewPassword'
import { SignIn } from '../signin/SignIn'
import { StartCheckerPage } from '../start-checker/page'
import { Terms } from '../terms/Terms'
import { TestHistoryItemPage } from '../test-history-item/page'
import { TestHistoryPage } from '../test-history/page'
import Video from '../video-calls/Video'

export const createRoutes = (isLoggedIn) => [
  {
    component: Auth0,
    routes: [isLoggedIn ? dashboardRoutes : initialRoutes],
  },
]

const initialRoutes = {
  component: SignInLayout,
  routes: [
    { path: ROUTES.START, exact: true, component: SignIn },
    { path: ROUTES.PRIVACY, exact: true, component: Privacy },
    { path: ROUTES.TERMS, exact: true, component: Terms },
  ],
}

const dashboardRoutes = {
  component: Layout,
  routes: [
    { path: ROUTES.DASHBOARD, exact: true, component: Dashboard },
    { path: ROUTES.SELF_ASSESSMENT_HISTORY, exact: true, component: SelfAssessmentHistory },
    { path: ROUTES.REWARDS, exact: true, component: RewardsProgram },
    { path: ROUTES.START_CHECKER, exact: true, component: StartCheckerPage },
    { path: ROUTES.CHECKER, exact: true, component: Checker },
    { path: `${ROUTES.RESOURCE}/:id`, exact: true, component: Resource },
    { path: ROUTES.RESOURCES, exact: true, component: Resources },
    { path: ROUTES.SETTINGS, exact: true, component: Settings },
    { path: ROUTES.HELP, exact: true, component: Help },
    { path: ROUTES.SUPPORT, exact: true, component: Support },
    { path: ROUTES.PRIVACY, exact: true, component: Consent },
    { path: ROUTES.TERMS, exact: true, component: Consent },
    { path: ROUTES.CONSENT, exact: true, component: Consent },
    { path: ROUTES.CONDITIONS, exact: true, component: Consent },
    { path: ROUTES.TEST_HISTORY, exact: true, component: TestHistoryPage },
    { path: ROUTES.TEST_HISTORY_ITEM, exact: true, component: TestHistoryItemPage },
    { path: ROUTES.PRIVACY, exact: true, component: Privacy },
    { path: ROUTES.TERMS, exact: true, component: Terms },
    { path: ROUTES.VIDEO, exact: true, component: Video },
    { path: ROUTES.NEW_PASSWORD, exact: true, component: NewPassword },
    { path: ROUTES.ACCOUNT_INFO, exact: true, component: AccountInfoPage },
    { path: ROUTES.EDIT_ACCOUNT, exact: true, component: EditAccountPage },
  ],
}
