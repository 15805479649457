import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../../constatants'
import { selectFirstLogin } from '../userSlice'

export function useIsFirstLogin() {
  const history = useHistory()
  const isFirstLogin = useSelector(selectFirstLogin)

  useEffect(() => {
    if (isFirstLogin) history.push(ROUTES.NEW_PASSWORD)
  }, [isFirstLogin])
}
