import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import BackIcon from '../../assets/Back_Arrow.svg'
import CloseIcon from '../../assets/ic_close.svg'
import { IconButton } from '../../components'
import { ThemeButton } from '../../components/ThemeButton/ThemeButton'
import { ROUTES } from '../../constatants'
import { useIsFirstLogin } from '../../features/auth0'
import { getConfig } from '../../features/configSlice'
import { Loading } from '../../ui'
import styles from './Checker.module.css'
import { Completed } from './Questions/Completed'
import { Number } from './Questions/Number'
import { Test } from './Questions/Test'
import {
  startChecker,
  selectQuestionnaire,
  nextQuestionnaireQuestion,
  resetChecker,
  goToPrevQuestion,
} from './checkerSlice'

export function Checker(props: RouteProps) {
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(true)
  let questionnaire = useSelector(selectQuestionnaire)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resetChecker())
    dispatch(startChecker())
    dispatch(getConfig())
  }, [])

  useEffect(() => {
    if (!(questionnaire.questionnaire && questionnaire.currentQuestion)) return

    const { questionnaire: questionnaireObj, currentQuestion } = questionnaire
    const currentResult = questionnaireObj.symptomChecker[currentQuestion.name]
    let result = !!currentResult
    if (_.isArray(currentResult)) result = !_.isEmpty(currentResult)
    setDisabled(!result)
  }, [questionnaire])

  useIsFirstLogin()

  const toNextQuestion = async () => {
    const {
      questionnaire: { id, symptomChecker },
    } = questionnaire

    setLoading(true)
    await dispatch(nextQuestionnaireQuestion(id, symptomChecker))
    setLoading(false)
    setDisabled(true)
  }

  const handleChange = (val) => {
    if (_.isObject(val)) return setDisabled(_.isEmpty(val))
    setDisabled(!val)
  }

  const goPrevQuestion = async () => {
    setLoading(true)
    await dispatch(goToPrevQuestion())
    setLoading(false)
  }

  if (loading) return <Loading />
  if (!questionnaire.questionnaire) return <Loading />

  const { questionnaire: questionnaireObj, currentQuestion, nbOfQuestions } = questionnaire
  const { outcome } = questionnaireObj
  const { nextQuestion } = outcome
  const isLastQuestion = nextQuestion === nbOfQuestions

  if (questionnaireObj.outcome.completed) return <Completed {...props} />

  return (
    <div
      style={{ paddingTop: 0, position: 'relative' }}
      className={`${styles.fullSettingsFrame} container pb-5 mt-3 flex-grow d-block`}
      data-page-title="Checker"
    >
      {questionnaire.currentQuestionNumber !== 1 ? (
        <IconButton onClick={goPrevQuestion} className={styles.closeBtn}>
          <img className={styles.backBtnImg} src={BackIcon} alt={'Back'} />
        </IconButton>
      ) : (
        <CloseButton />
      )}
      <div className={`${styles.checkerContainer} d-flex`}>
        <div className={styles.checkerItemContainer}>
          {currentQuestion.type === 'TEXT' && <Test questionnaire={questionnaire} onChange={handleChange} {...props} />}
          {(currentQuestion.type === 'NUMBER' || currentQuestion.type === 'DECIMAL') && (
            <Number questionnaire={questionnaire} onChange={handleChange} {...props} />
          )}
        </div>
        <div className={styles.checkerButtonContainer}>
          <ThemeButton disabled={disabled} onClick={toNextQuestion} type={'standard'}>
            {isLastQuestion ? 'Submit' : 'Continue'}
          </ThemeButton>
        </div>
      </div>
    </div>
  )
}

const CloseButton = () => {
  const history = useHistory()

  const goToDashboard = () => {
    history.push(ROUTES.DASHBOARD)
  }

  return (
    <IconButton onClick={goToDashboard} className={styles.closeBtn}>
      <img className={styles.closeBtnImg} src={CloseIcon} alt={'Close'} />
    </IconButton>
  )
}
