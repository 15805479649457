import classNames from 'classnames'
import React, { useState } from 'react'
import { ThemeContext } from '../../features/auth0/Auth0'
import styles from './ThemeButton.module.css'

interface ButtonProps {
  onClick: () => void
  type?: 'standard' | 'outline'
  disabled?: boolean
}

export const ThemeButton: React.FC<ButtonProps> = ({ onClick, type = 'standard', disabled, children }) => {
  const [buttonType, setButtonType] = useState(type)

  const containerClasses = classNames({
    'w-100': true,
    'text-center': true,
    'mt-3': true,
    'd-flex': true,
    'justify-content-center': true,
    [styles.disabledButton]: disabled,
    'cursor-pointer': true,
  })

  const classes = classNames({
    'text-uppercase': true,
    [styles.actionBtn]: true,
    [styles.actionBtnTop]: true,
    [styles.noOutline]: true,
    'd-flex': true,
    'justify-content-center': true,
    'align-items-center': true,
  })

  const isStandardButton = () => buttonType === 'standard'

  const createThemeStyles = (theme) => ({
    backgroundColor: isStandardButton() ? theme.appThemeColor : 'white',
    color: isStandardButton() ? 'white' : theme.appThemeColor,
    border: isStandardButton() ? 0 : `1px solid ${theme.appThemeColor}`,
  })

  const handleOnHover = () => {
    if (disabled) return null
    setButtonType(isStandardButton() ? 'outline' : 'standard')
  }

  const handleOnClick = (args) => {
    if (disabled) return null
    onClick.apply(this, args)
  }

  return (
    <ThemeContext.Consumer>
      {(theme: any) => (
        <div className={containerClasses}>
          <span
            style={createThemeStyles(theme)}
            onClick={handleOnClick}
            onMouseOver={handleOnHover}
            onMouseOut={handleOnHover}
            className={classes}
          >
            {children}
          </span>
        </div>
      )}
    </ThemeContext.Consumer>
  )
}
