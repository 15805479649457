import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { ThemeContext } from '../../../features/auth0'
import { N } from '../../../libs/utils'
import { Loading } from '../../../ui'
import { setQuestionResult, CheckerState } from '../checkerSlice'
import styles from './Test.module.css'

interface NumberInterface extends RouteProps {
  onChange: (val) => void
  questionnaire: CheckerState
}

export function Number({ onChange, questionnaire }: NumberInterface) {
  const dispatch = useDispatch()

  const { questionnaire: questionnaireObj, currentQuestion } = questionnaire
  const { introduction, minimumNumber, maximumNumber } = currentQuestion

  const step = currentQuestion.type === 'DECIMAL' ? 0.1 : 1

  const setResult = (event) => {
    const result = +event.target.value
    dispatch(setQuestionResult(currentQuestion.name, result))
    onChange(result)
  }

  useEffect(() => {
    let currentValue = questionnaireObj.symptomChecker[currentQuestion.name]
    dispatch(setQuestionResult(currentQuestion.name, currentValue || minimumNumber))
    onChange(minimumNumber)
  }, [])

  const sliderDecrement = () => {
    let currentValue = questionnaireObj.symptomChecker[currentQuestion.name] || 0
    let nextValue = N(currentValue - step)
    if (nextValue < minimumNumber) return
    dispatch(setQuestionResult(currentQuestion.name, nextValue))
  }
  const sliderIncrement = () => {
    let currentValue = questionnaireObj.symptomChecker[currentQuestion.name] || 0
    let nextValue = N(currentValue + step)
    if (nextValue > maximumNumber) return
    dispatch(setQuestionResult(currentQuestion.name, nextValue))
  }

  if (!questionnaire) return <Loading />

  const currentValue = questionnaireObj.symptomChecker[currentQuestion.name] || minimumNumber

  return (
    <ThemeContext.Consumer>
      {(theme: any) => (
        <div className={`${styles.questionWrapper} text-center mx-auto d-flex flex-grow`}>
          <div className="row flex-grow">
            <div className="col-12 fb-auto mb-auto">
              <div className={`${styles.checkerQuestion} ${styles.sliderQuestion}`}>{introduction}</div>
            </div>
            <div className="col-12 fb-auto mb-auto">
              <p className={styles.rangeSliderValue}>
                <span id="Temp_Value" />
                {currentQuestion.type === 'DECIMAL' ? 'Fahrenheit' : ''} {currentValue}
              </p>
              <div className={styles.rangeSliderWrapper}>
                <span className={`${styles.rsArrow} ${styles.rsArrowLeft}`} onClick={sliderDecrement}>
                  <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <g id="icon/arrowright" fill={theme.appThemeColor}>
                        <path
                          d="M12.5036108,10.4014022 L7.37696365,16.0943348 C6.87299277,16.6539739 6.0107665,16.6991019 5.45112739,16.1951311 C4.89148828,15.6911602 4.84636026,14.8289339 5.35033114,14.2692948 L11.4902946,7.45111579 C12.0319513,6.84962807 12.9752704,6.84962807 13.5169271,7.45111579 L19.6496859,14.2612943 C20.1536567,14.8209334 20.1085287,15.6831597 19.5488896,16.1871305 C18.9892505,16.6911014 18.1270242,16.6459734 17.6230533,16.0863343 L12.5036108,10.4014022 Z"
                          id="shape"
                          transform="translate(12.500008, 11.772731) scale(-1, 1) rotate(-90.000000) translate(-12.500008, -11.772731) "
                        />
                      </g>
                    </g>
                  </svg>
                </span>
                <div className={styles.rangeSliderInner}>
                  <input
                    type="range"
                    min={minimumNumber}
                    max={maximumNumber}
                    value={currentValue}
                    step={step.toString()}
                    onChange={setResult}
                    className={`${styles.rangeSlider} temp-slider`}
                    id="Temp_Slider"
                  />
                </div>
                <span className={`${styles.rsArrow}`} onClick={sliderIncrement}>
                  <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <g id="icon/arrowright" fill={theme.appThemeColor}>
                        <path
                          d="M12.5036108,10.4014022 L7.37696365,16.0943348 C6.87299277,16.6539739 6.0107665,16.6991019 5.45112739,16.1951311 C4.89148828,15.6911602 4.84636026,14.8289339 5.35033114,14.2692948 L11.4902946,7.45111579 C12.0319513,6.84962807 12.9752704,6.84962807 13.5169271,7.45111579 L19.6496859,14.2612943 C20.1536567,14.8209334 20.1085287,15.6831597 19.5488896,16.1871305 C18.9892505,16.6911014 18.1270242,16.6459734 17.6230533,16.0863343 L12.5036108,10.4014022 Z"
                          id="shape"
                          transform="translate(12.500008, 11.772731) scale(-1, 1) rotate(-90.000000) translate(-12.500008, -11.772731) "
                        />
                      </g>
                    </g>
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </ThemeContext.Consumer>
  )
}
