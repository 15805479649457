import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import arrowIcon from '../../assets/arrow-icon.svg'
import { ROUTES } from '../../constatants'
import { useIsFirstLogin } from '../../features/auth0'
import { getResources, selectResourcesList } from '../../features/resourcesSlice'
import { Loading } from '../../ui'
import styles from './Resources.module.css'

export function Resources({ history }: RouteProps) {
  const dispatch = useDispatch()
  const list = useSelector(selectResourcesList)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    ;(async function () {
      await dispatch(getResources())
      setLoading(false)
    })()
  }, [])
  useIsFirstLogin()

  if (loading) return <Loading />

  return (
    <div className={`${styles.fullSettingsFrame} container-fluid mb-5 mt-3`}>
      <div className="row">
        <div className={`col-md-12`}>
          <div className={`${styles.resourcesHolder} my-3`}>
            <ul className={`${styles.borderRadius5} bg-white d-flex flex-column list-unstyled pt-0 pr-0 pb-0 m-0`}>
              {!list.length && <NoResources />}
              {list.map((i) => (
                <li key={i.id}>
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      history.push(`${ROUTES.RESOURCE}/${i.id}`)
                    }}
                    className={`${styles.resourceItem} w-100 d-flex align-items-center`}
                  >
                    <div className={`${styles.resourceInfo}`}>{i.title}</div>
                    <div className="date-arrow-wrapper ml-auto d-flex">
                      <img
                        src={arrowIcon}
                        alt="arrow-icon"
                        className={`${styles.arrowIcon} mr-2 ml-auto d-flex align-items-center`}
                      />
                    </div>
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

const NoResources = () => <p style={{ margin: 0, textAlign: 'center' }}>No testing resources</p>
