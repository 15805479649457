import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { Provider } from 'react-redux'
import App from './App'
import { store } from './app/store'
import { createRoutes } from './pages/routes'
import * as serviceWorker from './serviceWorker'

const { NODE_ENV } = process.env

if (NODE_ENV !== 'development')
  Sentry.init({
    dsn: 'https://d272cbc744184fb7a269949ee53efead@o463450.ingest.sentry.io/5493566',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.5,
    environment: NODE_ENV,
  })

export {}
declare global {
  interface Window {
    flowplayer: any
    alertify: any
  }
  interface RouteProps {
    history: any
    location: any
    route: any
  }
}

try {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register(`./firebase-messaging-sw.js`)
      .then((registration) => {
        console.log('Registration successful, scope is:', registration.scope)
      })
      .catch((err) => {
        console.log('Service worker registration failed, error:', err)
      })
  }
} catch (e) {
  console.log('Service worked was not imported')
}

const accessToken = localStorage.getItem('access_token')
const idToken = localStorage.getItem('id_token')
const routes = createRoutes(accessToken && idToken)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App routes={routes} />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)

serviceWorker.unregister()
