import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import arrowIcon from '../../assets/arrow-icon.svg'
import { ROUTES } from '../../constatants'
import { useIsFirstLogin } from '../../features/auth0'
import { getTestHistory, getTestHistoryMore, selectTestHistory } from '../../features/test-history/model'
import { Observer } from '../../libs/observer'
import { getAtSeparatedDate } from '../../libs/utils'
import { Loading } from '../../ui'

export const TestHistoryPage = () => {
  useIsFirstLogin()
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const { list: testHistoryList, isLoading, isDone } = useSelector(selectTestHistory)

  React.useEffect(() => {
    dispatch(getTestHistory())
  }, [])

  const onItemClick = (thItemId: string) => () => {
    history.push(`${ROUTES.TEST_HISTORY}/${thItemId}`)
  }

  const getMoreItems = () => {
    dispatch(getTestHistoryMore())
  }

  if (isLoading && testHistoryList.length === 0) return <Loading />

  return (
    <div className={classes.card}>
      <ul className={classnames(classes.list, 'list-unstyled')}>
        {testHistoryList.length === 0 && <p className={classes.noItemsTitle}>No items</p>}
        {testHistoryList.map((i, index) => (
          <React.Fragment key={i._id}>
            {testHistoryList.length >= 20 && !isDone && testHistoryList.length - 5 === index && (
              <Observer callback={getMoreItems} />
            )}
            <li className={classes.testHistoryWrapper}>
              <div onClick={onItemClick(i._id)} className={classes.testHistoryItem}>
                <div className={classes.textsWrapper}>
                  <div className={classes.texts}>
                    <Typography className={classnames(classes.leftText, classes.resourceTitle)}>
                      {i.details.orderableTest ? i.details.orderableTest.customName : 'Unknown'}
                    </Typography>
                    <Typography
                      className={classnames(classes.secondTitle, {
                        [classes.resultCompleted]: i.statusDisplayText === 'Completed',
                      })}
                    >
                      {i.statusDisplayText}
                    </Typography>
                  </div>
                  <div className={classes.texts}>
                    <Typography className={classes.leftText}>
                      {i.details.labVendor ? i.details.labVendor.name : 'Unknown'}
                    </Typography>
                    {i.created && <Typography>{getAtSeparatedDate(i.created)}</Typography>}
                  </div>
                </div>
                <div className="date-arrow-wrapper ml-auto d-flex">
                  <img src={arrowIcon} alt="arrow-icon" className={classes.arrowIcon} />
                </div>
              </div>
            </li>
          </React.Fragment>
        ))}
      </ul>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  list: {
    margin: 0,
  },
  noItemsTitle: {
    padding: '1rem',
  },
  resultCompleted: {
    color: '#1a9a4c',
  },
  card: {
    [theme.breakpoints.up(1023)]: {
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
      maxWidth: 640,
      minWidth: 640,
      borderRadius: 4,
      margin: '2rem auto',
      background: '#fff',
    },
  },
  textsWrapper: {
    display: 'grid',
    gridGap: '0.5rem',
  },
  texts: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  leftText: {
    marginRight: '0.5rem',
    flex: 1,
  },
  testHistoryWrapper: {
    borderBottom: '1px solid #D0D0CD',
    '&:last-child': {
      border: 'none',
    },
  },
  testHistoryItem: {
    padding: 15,
    color: '#666666',
    textDecoration: 'none',
    cursor: 'pointer',
    display: 'grid',
    gridTemplateColumns: '1fr min-content',
    gridGap: '1rem',
    '&:hover': {
      color: '#171D49',
    },
  },
  resourceTitle: {
    fontSize: 18,
    fontWeight: 600,
    fontFamily: 'SF Pro Light',
    lineHeight: '19px',
    [theme.breakpoints.down(768)]: {
      fontSize: 16,
    },
  },
  secondTitle: {
    fontSize: 14,
  },
  arrowIcon: {
    width: 9,
  },
}))
