import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment-timezone'
import api from '../../api'
import { AppThunk, RootState } from '../../app/store'

export interface SelfAssessmentHistoryState {
  initializing: boolean
  isLoading: boolean
  items: any[]
  totalAssessmentDays: number
}

const initialState: SelfAssessmentHistoryState = {
  initializing: true,
  isLoading: false,
  totalAssessmentDays: 0,
  items: [],
}

const processSelfAssessments = (items, timezone) => {
  const response = {
    totalAssessmentDays: 0,
    items: [{ id: 'Today', items: [] }],
  }

  const today = moment().tz(timezone).startOf('day')
  const yesterday = moment(new Date()).tz(timezone).subtract(1, 'days')
  const cutoffday = moment(new Date('August 26, 2020')).tz(timezone)

  const getDaysBetweenDates = (startDate, endDate) => {
    let now = startDate.tz(timezone).clone()
    let dates = []
    while (now.isSameOrBefore(endDate, 'day')) {
      if (now.isSame(today, 'day')) {
        dates.push('Today')
      } else if (now.isSame(yesterday, 'day')) {
        dates.push('Yesterday')
      } else {
        dates.push(now.format('ddd, M/DD/YYYY'))
      }
      now.add(1, 'days')
    }
    return dates.reverse()
  }

  const filteredItems = items.filter((item) => {
    return moment(item.completed || item.started).isSameOrAfter(cutoffday) && item.outcome.option !== null
  })
  const lastItem = filteredItems[filteredItems.length - 1]
  if (lastItem) {
    const days = getDaysBetweenDates(moment(lastItem.completed || lastItem.started), today)
    let stateItems = days.map((day) => {
      return { id: day, items: [] }
    })

    filteredItems.forEach((item) => {
      item.isCleared = item.outcome.option === 'ASYMPTOMATIC'
      const assessmentDate = moment(item.completed || item.started)
      let id = assessmentDate.tz(timezone).format('ddd, M/DD/YYYY')
      if (assessmentDate.isSame(today, 'day')) {
        id = 'Today'
      }

      if (assessmentDate.isSame(yesterday, 'day')) {
        id = 'Yesterday'
      }

      let assessment = stateItems.find((s) => s.id === id)
      if (assessment && assessment.items) {
        let daysAdded = assessment.items.find((x) => x.completed !== null)
        if (!daysAdded && item.completed) {
          response.totalAssessmentDays += 1
        }
        assessment.items.push(item)
      }
    })

    response.items = stateItems
  }

  return response
}

export const assessmentHistorySlice = createSlice({
  name: 'assessmentHistory',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.isLoading = payload
    },
    setAssessmentHistory: (state, { payload }) => {
      let response = processSelfAssessments(payload.items, payload.timezone)
      state.initializing = false
      state.totalAssessmentDays += response.totalAssessmentDays
      state.items = [...state.items, ...response.items]
      state.isLoading = false
    },
    resetAssessmentHistory: () => initialState,
  },
})

export const { setLoading, setAssessmentHistory, resetAssessmentHistory } = assessmentHistorySlice.actions

export const getAssessmentHistory = (): AppThunk => async (dispatch, getState) => {
  dispatch(setLoading(true))
  const [result] = await api.getSelfAssessmentHistory(80, 0)
  if (result.items.length < result.total) {
    const [response] = await api.getSelfAssessmentHistory(result.total, 0)
    response.timezone = getState().tenantConfig.reportingTimezone
    dispatch(setAssessmentHistory(response))
  } else {
    result.timezone = getState().tenantConfig.reportingTimezone
    dispatch(setAssessmentHistory(result))
  }
}

export const reset = (): AppThunk => async (dispatch) => {
  dispatch(resetAssessmentHistory())
}

export const selectAssessmentHistory = (state: RootState) => state

export default assessmentHistorySlice.reducer
