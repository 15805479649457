import MUITextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import classNames from 'classnames'
import { useFormik } from 'formik'
import React from 'react'
import { Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import api from '../../api'
import { DatePicker } from '../../components/date-picker'
import { PhoneInput } from '../../components/phone-input'
import { allowedGenders, listOfCountries, USAStateList } from '../../constatants'
import { useIsFirstLogin } from '../../features/auth0'
import { selectTenantConfig } from '../../features/tenantConfigSlice'
import { selectProfile, setProfile } from '../../features/userSlice'
import { TextField } from '../../ui'
import styles from './edit-account.module.css'

const today = new Date()
const minDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate())
const validationSchema = Yup.object().shape({
  birthDate: Yup.date()
    .nullable()
    .typeError('Please enter a valid date')
    .required('Required')
    .max(minDate, 'You must to be at least 18 years old'),
  phone: Yup.string().nullable().required('Required'),
  code: Yup.string().nullable(),
  street: Yup.string().nullable().required('Required'),
  apt: Yup.string().nullable(),
  city: Yup.string().nullable().required('Required'),
  state: Yup.string().nullable().required('Required'),
  country: Yup.string().nullable().required('Required'),
  zip: Yup.string().nullable().max(6, '6 chars max').required('Required'),
})

export const EditAccountPage = () => {
  const tenantConfig = useSelector(selectTenantConfig)
  const user = useSelector(selectProfile)
  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(setProfile())
  }, [])
  useIsFirstLogin()

  const formik = useFormik({
    initialValues: {
      birthDate: user && user.birthDate ? new Date(user.birthDate) : null,
      code: user ? user.contact.mobileCountryCode : '',
      phone: user ? user.contact.mobileNumber : '',
      street: user ? user.primaryAddress.line1 : '',
      apt: user ? user.primaryAddress.line2 || '' : '',
      city: user ? user.primaryAddress.city : '',
      state: user ? user.primaryAddress.state : '',
      country: user ? user.primaryAddress.country || 'USA' : '',
      zip: user ? user.primaryAddress.zip : '',
    },
    onSubmit({ street, state, phone, code, country, city, apt, birthDate, zip }) {
      api
        .updateAccount({
          ...user,
          birthDate: new Date(birthDate).toISOString(),
          primaryAddress: {
            ...user.primaryAddress,
            line1: street,
            line2: apt,
            city,
            state,
            country,
            zip,
          },
          contact: {
            ...user.contact,
            mobileNumber: phone,
            faxCode: code,
            homeCountryCode: code,
            mobileCountryCode: code,
            workCountryCode: code,
          },
        })
        .then(([res, err]) => {
          formik.setSubmitting(false)
          if (res) window.alertify.success('Account info has been updated')
          else if (err) window.alertify.warning('Something went wrong...')
        })
    },
    enableReinitialize: true,
    validationSchema,
  })

  const getGenderValue = () => {
    const gender = allowedGenders.find(({ value }) => user.gender === value)
    if (!gender) return null
    return gender.label
  }

  if (!user) return

  return (
    <div className={`${styles.fullFormFrame} container page-content mb-5 pt-0 mt-3`}>
      <form className="row" noValidate onSubmit={formik.handleSubmit}>
        <div className={`col-md-12 ${styles.editContainer}`}>
          <h2 className={styles.homeHeader}>Personal information</h2>
          <div className={styles.formWrapper}>
            <div className={styles.inputGrid}>
              <TextField label={'First Name'} readOnly disabled value={user.firstName} />
              <TextField label={'Last Name'} readOnly disabled value={user.lastName} />
            </div>
            <TextField label={'Sex'} readOnly disabled value={getGenderValue()} />
            <DatePicker
              required={!!formik.touched.birthDate}
              value={formik.values.birthDate}
              onChange={(e) => formik.setFieldValue('birthDate', e.target.value)}
              onBlur={() => formik.setFieldTouched('birthDate')}
              helperText={formik.errors.birthDate as string}
            />
            <TextField label={'Email'} readOnly disabled value={user.email} />
            <PhoneInput
              value={formik.values.phone}
              code={formik.values.code}
              onChange={({ value, code }) => {
                formik.setFieldValue('phone', value)
                formik.setFieldValue('code', code)
              }}
              onBlur={() => formik.setFieldTouched('phone')}
              helperText={formik.errors.phone}
              required
            />
          </div>
        </div>
        <div className={`col-md-12 ${styles.editContainer}`}>
          <h2 className={styles.homeHeader}>Home address</h2>
          <div className={styles.formWrapper}>
            <TextField
              label={'Street Address'}
              value={formik.values.street}
              onChange={formik.handleChange}
              name={'street'}
              required
              error={formik.touched.street && formik.errors.street}
              onBlur={() => formik.setFieldTouched('street')}
            />
            <TextField label={'Apt/Ste'} value={formik.values.apt} onChange={formik.handleChange} name={'apt'} />
            <TextField
              label={'City'}
              value={formik.values.city}
              onChange={formik.handleChange}
              name={'city'}
              required
              error={formik.touched.city && formik.errors.city}
              onBlur={() => formik.setFieldTouched('city')}
            />
            <Autocomplete
              options={USAStateList}
              getOptionLabel={({ label }) => label}
              value={USAStateList.find(({ value }) => formik.values.state === value)}
              disableClearable
              onChange={(e, newValue: { value: string; label: string }) => {
                formik.setFieldValue('state', newValue.value)
              }}
              renderInput={(params) => (
                <MUITextField
                  {...params}
                  label={'State'}
                  required
                  error={formik.touched.state && !!formik.errors.state}
                  helperText={formik.errors.state}
                  onBlur={() => formik.setFieldTouched('state')}
                />
              )}
            />
            <Autocomplete
              options={listOfCountries}
              getOptionLabel={({ label }) => label}
              value={listOfCountries.find(({ value }) => formik.values.country === value)}
              disableClearable
              onChange={(e, newValue: { value: string; label: string }) => {
                formik.setFieldValue('country', newValue.value)
              }}
              renderInput={(params) => (
                <MUITextField
                  {...params}
                  label={'Country'}
                  required
                  error={formik.touched.country && !!formik.errors.country}
                  helperText={formik.errors.country}
                  onBlur={() => formik.setFieldTouched('country')}
                />
              )}
            />
            <TextField
              label={'Zip Code'}
              value={formik.values.zip}
              onChange={formik.handleChange}
              name={'zip'}
              required
              error={formik.touched.zip && formik.errors.zip}
              onBlur={() => formik.setFieldTouched('zip')}
            />
          </div>
          <button
            style={{ backgroundColor: tenantConfig.appThemeColor }}
            className={classNames(
              styles.saveBtn,
              'btn',
              'btn-lg',
              'btn-block',
              'text-white',
              'd-flex',
              'align-items-center',
              'justify-content-center',
            )}
            type="submit"
            disabled={formik.isSubmitting}
          >
            {formik.isSubmitting ? (
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            ) : (
              'save'
            )}
          </button>
        </div>
      </form>
    </div>
  )
}
