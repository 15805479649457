import React from 'react'
import { getTenantName } from '../../libs/helpers'
import { Loading } from '../../ui'
import styles from './RewardsProgram.module.css'

export function RewardsProgram() {
  if (getTenantName() !== 'delta') return <Loading />

  return (
    <div className={`${styles.fullFrame} container-fluid mb-5 mt-3`}>
      <div className="row">
        <div className={`${styles.titleContainer} col-md-12`}>
          <div className={`${styles.title} text-center mb-1`}>
            <span>Earn rewards while keeping yourself and your team members safe!</span>
          </div>
        </div>
        <div className={`${styles.details} col-md-12`}>
          <p>Employees can now earn Delta Health Rewards (DHR) for conducting a voluntary symptom self-assessment prior to coming to work, either via the Delta Care Check app or through a new Delta Care Check web portal. Assessing your symptoms and staying home if you are experiencing any COVID-19 symptoms is a key action in our multi-layered defense to Stop the Spread, Save Lives.</p>
          <p>For every 20 cumulative days that you conduct a self-assessment check-in, you'll receive $50 in Delta Health Rewards for 2021, up to a maximum of $200. The funds will be deposited into your HSA or HRA, depending on which healthcare plan you are enrolled in.</p>
          <p>Employees who use the Delta Care Check app or the new Delta Care Check web portal in 2020 will earn credit for 2021 DHRs for every 20 cumulative days of completing a self-assessment. Every 20 days is worth $50, for an annual max of $200, and employees can complete this action up to four times from now until the end of 2021.</p>
          <p>Spouses are not eligible to earn for this action.</p>
          <p>Employees will see credit for the Delta Care Check actions completed in 2020, starting in 2021 (this will not show up on your Rally dashboard but will show as a completed action through Sharecare early in 2021).</p>
        </div>
      </div>
    </div>
  )
}
