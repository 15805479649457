import { createSlice } from '@reduxjs/toolkit'
import api from '../api'
import { AppThunk, RootState } from '../app/store'

const initialState = null

export const configSlice = createSlice({
  name: 'сonfig',
  initialState,
  reducers: {
    setConfig: (state, action) => {
      return action.payload
    },
  },
})

export const { setConfig } = configSlice.actions

export const getConfig = (): AppThunk => async (dispatch) => {
  const [result, error] = await api.getConfigs()
  dispatch(setConfig(result))
}

export const selectConfig = (state: RootState) => state.config

export default configSlice.reducer
