import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import api from '../api'
import { AppThunk, RootState } from '../app/store'
import { getAuthClient } from '../libs/auth0'
import { buildTenantEnv } from '../libs/helpers'

const initialState = {
  deviceType: 'web',
  deviceModel: 'browser',
  deviceToken: null,
  isLoggedIn: false,
  userId: null,
}

export const deviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    updateDeviceInfo: (state, action) => {
      const { deviceToken, isLoggedIn, userId } = action.payload
      return _.merge(state, {
        deviceToken,
        isLoggedIn,
        userId,
      })
    },
  },
})

export const { updateDeviceInfo } = deviceSlice.actions

export const updateDevice = (data): AppThunk => async (dispatch, getState) => {
  const state = _.clone(getState().device)
  const [result, error] = await api.updateDeviceInfo(_.extend(state, data))
  if (result) dispatch(updateDeviceInfo(result))
}

export const doLogout = (force = false): AppThunk => async (dispatch, getState) => {
  const auth0 = getAuthClient()
  if (!force) {
    const state = getState().device
    const update = {
      deviceToken: initialState.deviceToken,
      isLoggedIn: initialState.isLoggedIn,
      userId: state.userId,
    }
    const [result, error] = await api.updateDeviceInfo(update)

    await dispatch(updateDeviceInfo(result))
  }

  localStorage.removeItem('access_token')
  localStorage.removeItem('id_token')
  localStorage.removeItem('user_id')

  auth0.logout({
    returnTo: window.location.origin,
    clientID: process.env[buildTenantEnv('AUTH0_CLIENT_ID')],
  })
}

export const selectDevice = (state: RootState) => state.device

export default deviceSlice.reducer
