export const countriesQuestion = {
  name: 'countryCode',
  description: null,
  help: null,
  introduction: 'Are you traveling to any of these countries on your next flight?',
  defaultValue: null,
  readOnly: false,
  required: true,
  multiple: false,
  type: 'TEXT',
  allowedValues: [
    { value: 'CN', displayValue: 'China' },
    { value: 'KR', displayValue: 'Korea' },
    { value: 'US', displayValue: 'Neither of these' },
  ],
  dateFormat: null,
  canBeUnknown: false,
  minimumNumber: 0.0,
  maximumNumber: 0.0,
}
