import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ThemeContext } from '../../../features/auth0'
import { selectIsIFSUserSegment } from '../../../features/userSlice'
import { getTenantName } from '../../../libs/helpers'
import { Loading } from '../../../ui'
import { setQuestionResult, CheckerState } from '../checkerSlice'
import styles from './Test.module.css'

interface TestInterface extends RouteProps {
  questionnaire: CheckerState
  onChange: (val) => void
}

export function Test({ onChange, questionnaire }: TestInterface) {
  const isIFSUserSegment = useSelector(selectIsIFSUserSegment)
  const dispatch = useDispatch()

  const { questionnaire: questionnaireObj, currentQuestion } = questionnaire
  const { multiple } = currentQuestion

  const setResult = (value) => {
    let result

    if (multiple) {
      result = _.clone(questionnaireObj.symptomChecker[currentQuestion.name]) || []

      if (value === 'NONE' && !_.includes(result, 'NONE')) {
        result = ['NONE']
      } else if (_.includes(result, value)) {
        result = _.filter(result, (i) => i !== value)
      } else {
        if (_.includes(result, 'NONE')) result = _.filter(result, (i) => i !== 'NONE')
        result.push(value)
      }
    } else {
      result = _.clone(questionnaireObj.symptomChecker[currentQuestion.name])
      result = result === value ? null : value
    }

    dispatch(setQuestionResult(currentQuestion.name, result))
    onChange(result)
  }

  const getResult = (value) => {
    const optionValue = questionnaireObj.symptomChecker[currentQuestion.name]
    return multiple ? _.includes(optionValue, value) : optionValue === value
  }

  const getText = (text) => {
    if (!text) return ''

    if (getTenantName() === 'delta' && isIFSUserSegment && /Feeling feverish/gi.test(text)) {
      return 'Feeling feverish'
    }

    return text
  }

  if (!questionnaire) return <Loading />

  const { introduction, allowedValues } = currentQuestion

  return (
    <ThemeContext.Consumer>
      {(theme: any) => (
        <div className={`${styles.diagnosticWrapper} text-center mx-auto`}>
          <div className={`${styles.flexGrow} flex-column`}>
            <div className={`col-12 mb-4 ${styles.fbAuto}`}>
              <div className={`${styles.checkerQuestion}`} style={{ color: theme.appThemeColor }}>
                {introduction}
              </div>
              <div className={styles.applyAllText}>{multiple ? 'Select all that apply' : ''}</div>
            </div>
            {_.map(allowedValues, (option, i) => {
              const isSelected = getResult(option.value)
              return (
                <div key={i} className={`col-12 mb-3 ${styles.fbAuto}`}>
                  <div onClick={() => setResult(option.value)} className="symptom-wrapper">
                    <label htmlFor="fever" className={`${styles.diseaseWrapper} position-relative mb-0 text-left`}>
                      <span
                        className={classNames(styles.diseaseName, {
                          [styles.selectedTitle]: isSelected,
                        })}
                        style={{ color: theme.appThemeColor }}
                      >
                        {getText(option.displayValue)}
                      </span>
                      <input type="checkbox" className={styles.checkboxDisease} id={option.value} />
                      <span className={styles.customCheckbox} />
                      {isSelected && (
                        <span className={styles.customCheckboxActive} style={{ borderColor: theme.appThemeColor }} />
                      )}
                    </label>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </ThemeContext.Consumer>
  )
}
