import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ROUTES } from '../../constatants'
import { ThemeContext, useIsFirstLogin } from '../../features/auth0'
import { getConsent, selectConsent, submitConsent } from '../../features/consentSlice'
import { getVerbiage, selectVerbiage } from '../../features/verbiageSlice'
import { Loading } from '../../ui'
import styles from './Terms.module.css'

export function Terms({ history }: RouteProps) {
  const dispatch = useDispatch()
  const verbiage = useSelector(selectVerbiage)
  const consents = useSelector(selectConsent)
  const [disabledButton, setDisabledButton] = useState(false)
  const docKey = 'TermsOfService'

  useEffect(() => {
    dispatch(getVerbiage())
    dispatch(getConsent())
  }, [])
  useIsFirstLogin()

  const acceptConsent = async () => {
    const result = await dispatch(submitConsent(docKey))
    if (result) history.push(ROUTES.DASHBOARD)
  }

  if (!(verbiage && consents)) {
    return <Loading />
  }

  const doc = verbiage.find((v) => v.key === docKey)
  const consented = consents.items.find((v) => v.key === docKey && v.consented && !v.archived)

  const renderConsent = (theme) => {
    if (!doc.needConsent || consented) return null

    return (
      <>
        <div className="pt-3 mb-3">
          <button
            style={{ backgroundColor: theme.appThemeColor }}
            onClick={acceptConsent}
            disabled={disabledButton}
            className={`${styles.noOutline} ${styles.signInBtn} btn btn-lg btn-block text-white d-flex align-items-center justify-content-center`}
            type="button"
          >
            {doc.confirmationConsentBtnLabel}
          </button>
        </div>
        <div className="mb-5">
          <button
            style={{ color: theme.appThemeColor }}
            disabled={disabledButton}
            onClick={() => {
              setDisabledButton(true)
            }}
            className={`${styles.noOutline} ${styles.laterBtn} btn btn-lg btn-block border-safe color-safe d-flex align-items-center justify-content-center`}
            type="button"
          >
            I will do it later
          </button>
        </div>
      </>
    )
  }

  return (
    <>
      <ThemeContext.Consumer>
        {(theme: any) => (
          <div className={`${styles.fullSettingsFrame} container-fluid mb-5 mt-3`}>
            <div className="row">
              <div className={`${styles.providerAppHolder} col-md-12 pt-5`}>
                <div className={`${styles.termsTitle} text-center mb-1`}>{doc.title}</div>
                <div dangerouslySetInnerHTML={{ __html: doc.value }} />
                {renderConsent(theme)}

                {/* <div className={`${styles.termsTitle} text-center mb-1`}>Terms and Conditions</div> */}
                {/* <div className={`${styles.importantText} text-uppercase color-safe font-weight-bold pt-3 mb-1`}> */}
                {/*  Important */}
                {/* </div> */}
                {/* <div className={styles.termsInfoWrapper}> */}
                {/*  <div className={`${styles.termsInfoInner} ${moreText ? 'active' : ''}`}> */}
                {/*    <p className={`${styles.termsText} mb-4`}> */}
                {/*      Please read the following terms before using the Delta App. By using the Delta App, you agree to */}
                {/*      be bound by the Terms of Service, the Privacy Policy, and Notice of Privacy Practices. If you */}
                {/*      choose to import any of your diagnostic test records, or if you choose to get tested through the */}
                {/*      Delta App, or both, you further agree to be bound by additional terms that will be presented to */}
                {/*      you when you choose those services, including the Informed Consent for Telehealth Services, the */}
                {/*      Consent to Release Protected Health Information, the Service Agreement and Informed Consent, and */}
                {/*      the Informed Consent to Perform HIV Testing, as applicable. */}
                {/*    </p> */}
                {/*    <p className={`${styles.termsText} mb-4`}> */}
                {/*      If you choose to import any of your diagnostic test records, or if you choose to get tested */}
                {/*      through the Delta App, or both, you further agree to be bound by additional terms that will be */}
                {/*      presented to you when you choose those services, including the Informed Consent for Telehealth */}
                {/*      Services, the Consent to Release Protected Health Information, the Service Agreement and Informed */}
                {/*      Consent, and the Informed Consent to Perform HIV Testing, as applicable. */}
                {/*    </p> */}
                {/*  </div> */}
                {/*  {moreText ? ( */}
                {/*    <span className={styles.readToggleButton} onClick={toggleTermsInfo}> */}
                {/*      less */}
                {/*    </span> */}
                {/*  ) : ( */}
                {/*    <span className={styles.readToggleButton} onClick={toggleTermsInfo}> */}
                {/*      more */}
                {/*    </span> */}
                {/*  )} */}
                {/* </div> */}
                {/* <a href="#" className={`${styles.pageLinkWrapper} d-flex justify-content-between`}> */}
                {/*  <div className="font-weight-bold" style={{ color: theme.appThemeColor }}> */}
                {/*    A. List Item */}
                {/*  </div> */}
                {/*  <i */}
                {/*    className={`${styles.arrowIcon} fa fa-chevron-right mr-3 ml-auto d-flex align-items-center`} */}
                {/*    style={{ color: theme.appThemeColor }} */}
                {/*  /> */}
                {/* </a> */}
                {/* <a href="#" className={`${styles.pageLinkWrapper} d-flex justify-content-between`}> */}
                {/*  <div className="font-weight-bold" style={{ color: theme.appThemeColor }}> */}
                {/*    B. Privacy policy */}
                {/*  </div> */}
                {/*  <i */}
                {/*    className={`${styles.arrowIcon} fa fa-chevron-right mr-3 ml-auto d-flex align-items-center`} */}
                {/*    style={{ color: theme.appThemeColor }} */}
                {/*  /> */}
                {/* </a> */}
                {/* <a href="#" className={`${styles.pageLinkWrapper} d-flex justify-content-between`}> */}
                {/*  <div className="font-weight-bold" style={{ color: theme.appThemeColor }}> */}
                {/*    C. Notice of Privacy Practices */}
                {/*  </div> */}
                {/*  <i */}
                {/*    className={`${styles.arrowIcon} fa fa-chevron-right mr-3 ml-auto d-flex align-items-center`} */}
                {/*    style={{ color: theme.appThemeColor }} */}
                {/*  /> */}
                {/* </a> */}
                {/* <a href="#" className={`${styles.pageLinkWrapper} d-flex justify-content-between`}> */}
                {/*  <div className="font-weight-bold" style={{ color: theme.appThemeColor }}> */}
                {/*    D. HIPAA Consent */}
                {/*  </div> */}
                {/*  <i */}
                {/*    className={`${styles.arrowIcon} fa fa-chevron-right mr-3 ml-auto d-flex align-items-center`} */}
                {/*    style={{ color: theme.appThemeColor }} */}
                {/*  /> */}
                {/* </a> */}
                {/* <a href="#" className={`${styles.pageLinkWrapper} d-flex mb-3 justify-content-between`}> */}
                {/*  <div className="font-weight-bold" style={{ color: theme.appThemeColor }}> */}
                {/*    E. Informed Conscent */}
                {/*  </div> */}
                {/*  <i */}
                {/*    className={`${styles.arrowIcon} fa fa-chevron-right mr-3 ml-auto d-flex align-items-center`} */}
                {/*    style={{ color: theme.appThemeColor }} */}
                {/*  /> */}
                {/* </a> */}
                {/* <div className="pt-3 mb-3"> */}
                {/*  <button */}
                {/*    style={{ backgroundColor: theme.appThemeColor }} */}
                {/*    className={`${styles.noOutline} ${styles.signInBtn} btn btn-lg btn-block text-white d-flex align-items-center justify-content-center`} */}
                {/*    type="button" */}
                {/*  > */}
                {/*    I Agree */}
                {/*  </button> */}
                {/* </div> */}
                {/* <div className="mb-5"> */}
                {/*  <button */}
                {/*    style={{ color: theme.appThemeColor }} */}
                {/*    className={`${styles.noOutline} ${styles.laterBtn} btn btn-lg btn-block border-safe color-safe d-flex align-items-center justify-content-center`} */}
                {/*    type="button" */}
                {/*  > */}
                {/*    I will do it later */}
                {/*  </button> */}
                {/* </div> */}
              </div>
            </div>
          </div>
        )}
      </ThemeContext.Consumer>
    </>
  )
}
