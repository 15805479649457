import { IconButton as MUIIconButton } from '@material-ui/core'
import React from 'react'

type IconButtonProps = {
  onClick?: (e: React.MouseEvent) => void
  className?: string
}
export const IconButton: React.FC<IconButtonProps> = ({ children, onClick, className }) => {
  return <MUIIconButton onClick={onClick}>{children}</MUIIconButton>
}
