import { createSlice } from '@reduxjs/toolkit'
import api from '../api'
import { AppThunk, RootState } from '../app/store'
import { getTenantName } from '../libs/helpers'

const initialState = null

export const consentSlice = createSlice({
  name: 'consent',
  initialState,
  reducers: {
    setConsent: (state, action) => {
      return action.payload
    },
  },
})

export const { setConsent } = consentSlice.actions

export const getConsent = (): AppThunk => async (dispatch, getState) => {
  let tenant = getTenantName()
  const [result, error] = await api.getConsent(tenant)
  if (result) dispatch(setConsent(result))
}

export const submitConsent = (verbiageKey): AppThunk => async (dispatch, getState) => {
  let tenant = getTenantName()
  const [result, error] = await api.submitConsent(tenant, verbiageKey)
  return result
}

export const selectConsent = (state: RootState) => state.consent

export default consentSlice.reducer
