import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { renderRoutes } from 'react-router-config'
import { ROUTES } from '../../constatants'
import { extendThemeContext } from '../../libs/helpers'
import { Loading } from '../../ui'
import { getTenantConfig, selectTenantConfig } from '../tenantConfigSlice'

export const ThemeContext = React.createContext({})

export function Auth0({ history, location, route }: RouteProps) {
  const tenantConfig = useSelector(selectTenantConfig)
  const dispatch = useDispatch()

  useEffect(() => {
    const accessToken = localStorage.getItem('access_token')
    const idToken = localStorage.getItem('id_token')

    if (accessToken && idToken) {
      if (location.pathname === ROUTES.START) history.replace(ROUTES.DASHBOARD)
    } else if (location.pathname !== ROUTES.START) {
      history.replace(ROUTES.START)
    }

    dispatch(getTenantConfig())
  }, [])

  if (!tenantConfig) return <Loading />

  const renderHelmet = () => {
    if (!tenantConfig) return
    const { keywords, shortDescription, name, favicon } = tenantConfig

    return (
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="icon" href={favicon} />
        <meta name="description" content={shortDescription} />
        <meta name="keywords" content={keywords} />
        <title>{name}</title>
      </Helmet>
    )
  }

  return (
    <>
      {renderHelmet()}
      <ThemeContext.Provider value={extendThemeContext(tenantConfig)}>
        {renderRoutes(route.routes)}
      </ThemeContext.Provider>
    </>
  )
}
