import { FormControl, FormLabel, FormHelperText } from '@material-ui/core'
import React, { useState } from 'react'
import PhoneInput2 from 'react-phone-input-2'
import styles from './phone-input.module.css'

type onChangeValue = {
  value: string
  code?: string
}

type DatePickerProps = {
  required: boolean
  value: string
  code: string
  onChange: (value: onChangeValue) => void
  onBlur: () => void
  helperText: string
}
export const PhoneInput: React.FC<DatePickerProps> = ({ value, code, required, onChange, onBlur, helperText }) => {
  const [phoneNumber, setPhoneNumber] = useState(value || '')
  const [countryCode, setCode] = useState(code || '')

  const onChangePhoneNumber = (changedPhoneNumber, country) => {
    const { dialCode } = country
    const number = dialCode ? changedPhoneNumber.slice(dialCode.length) : changedPhoneNumber

    setPhoneNumber(number)
    setCode(dialCode || '')

    onChange({
      value: number,
      code: dialCode,
    })
  }

  return (
    <FormControl required={required} className={styles.phoneControl}>
      <FormLabel error={!!helperText}>Phone Number</FormLabel>
      <PhoneInput2
        country={'us'}
        inputClass={styles.phoneInput}
        buttonClass={styles.phoneButton}
        value={phoneNumber ? countryCode + phoneNumber : null}
        onChange={onChangePhoneNumber}
        onBlur={onBlur}
      />
      <FormHelperText error>{helperText}</FormHelperText>
    </FormControl>
  )
}
