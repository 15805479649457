import classNames from 'classnames'
import React from 'react'
import { ThemeContext } from '../../features/auth0/Auth0'
import styles from './ThemeInput.module.css'

type InputProps = {
  error?: boolean
  required?: boolean
  name?: string
  onChange?: (e: React.ChangeEvent) => void
  onBlur?: (e: React.FocusEvent) => void
  onFocus?: (e: React.FocusEvent) => void
  value?: string | number
  helperText?: any
  label?: string
  type?: string
  id?: string
  placeholder?: string
  endAdornment?: React.ReactNode
  readonly?: boolean
  disabled?: boolean
}
export const ThemeInput = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { endAdornment, helperText, error, value, onChange, onFocus, readonly, disabled, ...attrs } = props
  const classes = classNames({
    [styles.noOutline]: true,
    'pl-10': true,
    'form-control': true,
    'border-0': true,
  })

  const errorMessageClasses = classNames({
    [styles.errorMessage]: props.error,
  })

  const renderError = () => {
    if (!error) return null

    return <p className={errorMessageClasses}>{helperText}</p>
  }

  return (
    <div className={styles.inputWrapper}>
      <ThemeContext.Consumer>
        {() => (
          <>
            <input
              {...attrs}
              className={classes}
              ref={ref}
              value={value}
              onChange={onChange}
              onFocus={onFocus}
              readOnly={readonly}
              disabled={disabled}
            />
            {endAdornment && <div className={styles.endAdornment}>{endAdornment}</div>}
            {renderError()}
          </>
        )}
      </ThemeContext.Consumer>
    </div>
  )
})
