import { createSlice } from '@reduxjs/toolkit'
import api from '../api'
import { AppThunk, RootState } from '../app/store'
import { convertArrayBufferToBlobUrl } from '../libs/utils'

const initialState = ''

export const userPhotoSlice = createSlice({
  name: 'userPhoto',
  initialState,
  reducers: {
    setPhotoUrl: (state, action) => {
      return action.payload
    },
  },
})

export const { setPhotoUrl } = userPhotoSlice.actions

export const getProfilePhoto = (): AppThunk => async (dispatch) => {
  const [result] = await api.getPicture()
  if (result) {
    const photoUrl = convertArrayBufferToBlobUrl(result, 'image/png')
    dispatch(setPhotoUrl(photoUrl))
  }
}

export const updateUserPhoto = (data): AppThunk => async (dispatch) => {
  const [result] = await api.updatePicture(data)
  if (result) dispatch(setPhotoUrl(URL.createObjectURL(data)))
}

export const selectPhotoUrl = (state: RootState) => state.userPhotoUrl

export default userPhotoSlice.reducer
