import _ from 'lodash'
import moment from 'moment'
import React, { useContext, useEffect } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import Cropper from 'react-cropper'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import docProfileImage from '../../assets/doc_profile.png'
import dangerSign from '../../assets/error.svg'
import greenCheckmark from '../../assets/green-checkmark.png'
import questionSign from '../../assets/question.svg'
import { ThemeButton } from '../../components/ThemeButton/ThemeButton'
import { ROUTES } from '../../constatants'
import { ThemeContext, useIsFirstLogin } from '../../features/auth0'
import { selectLatestResult, getLatestResult } from '../../features/latestResultSlice'
import { getResources, selectResourcesList } from '../../features/resourcesSlice'
import { selectPhotoUrl, updateUserPhoto } from '../../features/userPhotoSlice'
import { selectProfile, getUserCode } from '../../features/userSlice'
import { getTenantName } from '../../libs/helpers'
import { dataURLtoBlob, capitalize } from '../../libs/utils'
import { Loading } from '../../ui'
import styles from './Dashboard.module.css'
import 'cropperjs/dist/cropper.css'

type State = {
  isOpenPopup: boolean
  isOpenNumbers: boolean
  isOpenNumbersNonIFS: boolean
  isUploadingInProgress: boolean
  newPhotoUrl: string | null
  codeImageUrl: string
}
type Actions =
  | { type: 'resetState' }
  | { type: 'openPopup' }
  | { type: 'closePopup' }
  | { type: 'saveCodeImageUrl'; result: string }
  | { type: 'closeOpenNumbers' }
  | { type: 'saveNewPhotoUrl'; result: string }
  | { type: 'startUpload' }
  | { type: 'openNumbers' }
  | { type: 'openNumbersNonIFS' }
  | { type: 'closeOpenNumbersNonIFS' }
const initialState: State = {
  isOpenPopup: false,
  isOpenNumbers: false,
  isOpenNumbersNonIFS: false,
  isUploadingInProgress: false,
  newPhotoUrl: null,
  codeImageUrl: '',
}
const reducer = (state: State, action: Actions) => {
  switch (action.type) {
    case 'resetState': {
      return {
        ...state,
      }
    }
    case 'closePopup': {
      return {
        ...state,
        isOpenPopup: false,
        newPhotoUrl: null,
        isUploadingInProgress: false,
      }
    }
    case 'openPopup': {
      return {
        ...state,
        isOpenPopup: true,
      }
    }
    case 'startUpload': {
      return {
        ...state,
        isUploadingInProgress: true,
      }
    }
    case 'saveCodeImageUrl': {
      return {
        ...state,
        codeImageUrl: action.result,
      }
    }
    case 'closeOpenNumbers': {
      return {
        ...state,
        isOpenNumbers: false,
      }
    }
    case 'openNumbers': {
      return {
        ...state,
        isOpenNumbers: true,
      }
    }
    case 'closeOpenNumbersNonIFS': {
      return {
        ...state,
        isOpenNumbersNonIFS: false,
      }
    }
    case 'openNumbersNonIFS': {
      return {
        ...state,
        isOpenNumbersNonIFS: true,
      }
    }
    case 'saveNewPhotoUrl': {
      return {
        ...state,
        newPhotoUrl: action.result,
      }
    }
  }
}
export function Dashboard() {
  const history = useHistory()
  const profile = useSelector(selectProfile)
  const userPhotoUrl = useSelector(selectPhotoUrl)
  const latestResult = useSelector(selectLatestResult)
  const contentList = useSelector(selectResourcesList)
  const theme = useContext(ThemeContext) as any
  const dispatch = useDispatch()

  const [
    { isOpenPopup, isOpenNumbers, isOpenNumbersNonIFS, isUploadingInProgress, newPhotoUrl, codeImageUrl },
    stateDispatch,
  ] = React.useReducer<React.Reducer<State, Actions>>(reducer, initialState)

  const photoUploadRef = React.useRef<HTMLInputElement>(null)
  const cropperRef = React.useRef<HTMLImageElement>(null)

  useEffect(() => {
    dispatch(getLatestResult())
    dispatch(getResources())
    ;(async function () {
      if (profile) {
        const result: any = await dispatch(getUserCode(profile.email))
        if (result) stateDispatch({ type: 'saveCodeImageUrl', result })
      }
    })()
  }, [profile])

  useIsFirstLogin()

  const goToChecker = () => {
    history.push(ROUTES.CHECKER)
  }

  const onPopupClose = () => {
    stateDispatch({ type: 'closePopup' })
  }

  const onNumbersPopupClose = () => {
    stateDispatch({ type: 'closeOpenNumbers' })
  }

  const onNumbersNonIFSPopupClose = () => {
    stateDispatch({ type: 'closeOpenNumbersNonIFS' })
  }

  const onFileChanged = (e) => {
    const { files } = e.target
    const reader = new FileReader()
    const uploadFile = files[0]

    reader.onload = (event) => {
      stateDispatch({ type: 'saveNewPhotoUrl', result: event.target.result as string })
    }
    reader.readAsDataURL(uploadFile)
  }

  const onFileUpload = async () => {
    if (!cropperRef.current) return
    stateDispatch({ type: 'startUpload' })
    const imageElement = cropperRef.current
    const { cropper } = imageElement as any
    await dispatch(updateUserPhoto(dataURLtoBlob(cropper.getCroppedCanvas().toDataURL())))
    onPopupClose()
  }

  const renderCropper = () => {
    if (!newPhotoUrl) return null
    return (
      <Cropper
        ref={cropperRef}
        src={newPhotoUrl}
        style={{ height: 300, width: '100%' }}
        aspectRatio={1}
        guides={false}
      />
    )
  }

  const renderPhotoUpdateButtons = () => {
    if (newPhotoUrl) {
      return (
        <button
          onClick={onFileUpload}
          type="button"
          className={`${styles.browseImage} btn btn-primary bg-safe text-white`}
        >
          {isUploadingInProgress ? (
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          ) : (
            'Upload Image'
          )}
        </button>
      )
    }

    return (
      <button
        onClick={() => {
          if (photoUploadRef.current) photoUploadRef.current.click()
        }}
        type="button"
        className={`${styles.browseImage} btn btn-primary bg-safe text-white`}
      >
        Browse
      </button>
    )
  }

  const getIsCheckedToday = (date: string) => {
    if (!date) return false
    return moment().isSame(moment(date), 'd')
  }

  if (!(profile && codeImageUrl && latestResult)) return <Loading />

  const { firstName, lastName, metadata } = profile
  const isIFSUserSegment = metadata['User Segment'] && /IFS/gi.test(metadata['User Segment'])

  const { lastQuestionnaire, outcomeState } = latestResult

  const isAsymptomatic = outcomeState === 'ASYMPTOMATIC'
  const isSymptomatic = outcomeState === 'SYMPTOMATIC'
  const isExposure = outcomeState === 'EXPOSURE'

  const wasCheckedToday = getIsCheckedToday(lastQuestionnaire)
  const isGrey = !lastQuestionnaire || !wasCheckedToday

  const getFontColor = () => 'text-white' || (isGrey ? 'text-black' : 'text-white')
  const getBgColor = (): string => {
    if (!wasCheckedToday) return theme.appThemeColor
    if (!isAsymptomatic) return '#E31D37'
    if (isAsymptomatic && lastQuestionnaire) return '#1A994C'

    return theme.appThemeColor
  }

  const renderLastAssesmentDate = () => {
    const dateMessage = lastQuestionnaire ? moment(lastQuestionnaire).format('dddd, MMMM Do') : 'N/A'
    return (
      <>
        <div className={`${styles.assesmentTitle} text-center ${getFontColor()}`}>Last Assessment</div>
        <div className={`${styles.assesmentDate} text-center ${getFontColor()} ${styles.assesmentTitleDate}`}>
          {dateMessage}
        </div>
      </>
    )
  }

  const renderDeltaPhoneNumber = () => {
    const getTenantTitle = () => {
      if (getTenantName() === 'delta') {
        return 'relevant Delta'
      }
      return capitalize(theme.companyName)
    }

    const message = `Tap here to call ${getTenantTitle()} Help Desks and order an at-home test.`

    if (!isIFSUserSegment) {
      return (
        <div
          className={`${styles.statusWrapper} ${styles.statusWrapperError} d-flex align-items-center`}
          onClick={() => {
            stateDispatch({ type: 'openNumbersNonIFS' })
          }}
        >
          <img src={dangerSign} alt="danger-sign" className={`${styles.questionSign}`} />
          <div className={`${styles.statusText} mr-2`}>{message}</div>
        </div>
      )
    }

    return (
      <div
        className={`${styles.statusWrapper} ${styles.statusWrapperError} d-flex align-items-center`}
        onClick={() => stateDispatch({ type: 'openNumbers' })}
      >
        <img src={dangerSign} alt="danger-sign" className={`${styles.questionSign}`} />
        <div className={`${styles.statusText} mr-2`}>{message}</div>
      </div>
    )
  }

  const renderBottomSection = () => {
    const notClearToWorkMessage = `You are not ${wasCheckedToday ? '' : 'yet'} cleared for work`

    if (!wasCheckedToday) {
      return (
        <div className={`${styles.statusWrapper} d-flex align-items-center`}>
          <img src={questionSign} alt="danger-sign" className={`${styles.questionSign}`} />
          <div className={`${styles.statusText} mr-2`}>{notClearToWorkMessage}</div>
        </div>
      )
    }

    if (!isAsymptomatic) {
      if (getTenantName() === 'delta') {
        return renderDeltaPhoneNumber()
      }
      return (
        <div className={`${styles.statusWrapper} d-flex align-items-center`}>
          <img src={dangerSign} alt="danger-sign" className={`${styles.questionSign}`} />
          <div className={`${styles.statusText} mr-2`}>{notClearToWorkMessage}</div>
        </div>
      )
    }

    if (isAsymptomatic && lastQuestionnaire) {
      return (
        <div className={`${styles.statusWrapper} d-flex align-items-center`}>
          <img src={greenCheckmark} alt="danger-sign" className={`${styles.questionSign}`} />
          <div className={`${styles.statusText} mr-2`}>{'You are cleared for work'}</div>
        </div>
      )
    }

    return (
      <div className={`${styles.statusWrapper} d-flex align-items-center`}>
        <img src={questionSign} alt="danger-sign" className={`${styles.questionSign}`} />
        <div className={`${styles.statusText} mr-2`}>{notClearToWorkMessage}</div>
      </div>
    )
  }

  const renderInfoMenuItem = () => {
    const atHomeTestContent = _.find(contentList, (c) => /Home Diagnostic Testing/gi.test(c.title))
    if (!atHomeTestContent) return null
    if (!(isSymptomatic || isExposure)) return null

    return (
      <div className={styles.numbersItem}>
        <span
          onClick={() => {
            history.push(`${ROUTES.RESOURCE}/${atHomeTestContent.id}`)
          }}
        >
          Order An At-Home Test
        </span>
      </div>
    )
  }

  return (
    <>
      <div className={`${styles.fullSettingsFrame} container-fluid`} id="signedInUserPage" data-page-title="Home">
        <div className={`${styles.mainWrapper}`} style={{ backgroundColor: getBgColor() }}>
          <div className="row">
            <div className={`col-md-12 ${styles.mainContainer}`}>
              {renderLastAssesmentDate()}
              <div
                onClick={() => stateDispatch({ type: 'openPopup' })}
                className={`${styles.uploadPlaceholderWrapper} d-flex flex-column align-items-center justify-content-center cursor-pointer mx-auto rounded-circle`}
              >
                <div
                  className={`${styles.uploadPlaceholder} d-flex flex-column align-items-center justify-content-center cursor-pointer mx-auto rounded-circle position-relative`}
                >
                  {userPhotoUrl ? (
                    <img
                      src={userPhotoUrl || docProfileImage}
                      className={`${styles.uploadingImage} rounded-circle`}
                      alt="user"
                      data-toggle="modal"
                      data-target="#uploadImage"
                    />
                  ) : (
                    <div className="no-user-image">
                      <div className="text-center mb-4">
                        <i className={`${styles.plusSign} fa fa-plus`} />
                      </div>
                      <div className={`${styles.addImageText}`}>Add Image</div>
                    </div>
                  )}
                </div>
              </div>
              <div className={`${styles.userName} text-center ${getFontColor()}`}>
                {firstName} {lastName}
              </div>
              <div className={`${styles.qrCode} text-center`}>
                {codeImageUrl ? <img src={codeImageUrl} className={styles.qrCodeImage} alt="qr-code" /> : null}
              </div>
            </div>
          </div>
        </div>
        {renderBottomSection()}
        <div className={`${styles.buttonWrapper}`} style={{ display: 'flex', flexDirection: 'column' }}>
          <ThemeButton onClick={goToChecker} type={isGrey ? 'standard' : 'outline'}>
            {`${lastQuestionnaire && wasCheckedToday ? 'Retake' : 'Take'} Self-Assessment`}
          </ThemeButton>
        </div>
      </div>

      <Modal show={isOpenNumbers} onHide={onNumbersPopupClose}>
        <Modal.Body className={'mb-0 p-0'}>
          <div className={styles.modalNumbersWrapper}>
            <div className={styles.numbersItem}>
              <a href="tel:+14047159920">
                <i className="fa fa-phone pr-3" />
                Call IFS OCC Manager
              </a>
            </div>
            <div className={styles.numbersItem}>
              <i className="fa fa-phone pr-3" />
              <a href="tel:+1-800-DAL-CREW">Call MST Desk</a>
            </div>
            <div className={styles.numbersItem}>
              <i className="fa fa-phone pr-3" />
              <a href="tel:+14047152324">Call Delta Nurse Line</a>
            </div>
            {renderInfoMenuItem()}
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={isOpenNumbersNonIFS} onHide={onNumbersNonIFSPopupClose}>
        <Modal.Body className={'mb-0 p-0'}>
          <div className={styles.modalNumbersWrapper}>
            <div className={styles.numbersItem}>
              <i className="fa fa-phone pr-3" />
              <a href="tel:+14047152324">Call Delta Nurse Line</a>
            </div>
            {renderInfoMenuItem()}
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={isOpenPopup} onHide={onPopupClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update photo</Modal.Title>
        </Modal.Header>
        <Modal.Body>{renderCropper()}</Modal.Body>
        <Modal.Footer className="justify-content-center">
          <input
            id="myInput"
            type="file"
            accept="image/jpeg, image/png"
            onChange={onFileChanged}
            ref={photoUploadRef}
            style={{ display: 'none' }}
          />
          {renderPhotoUpdateButtons()}
        </Modal.Footer>
      </Modal>
    </>
  )
}
