import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ThemeButton } from '../../components/ThemeButton/ThemeButton'
import { ROUTES } from '../../constatants'
import { useIsFirstLogin } from '../../features/auth0'
import { getTenantConfig, selectTenantConfig } from '../../features/tenantConfigSlice'
import { selectProfile, setProfile } from '../../features/userSlice'
import styles from './start-checker.module.css'

export const StartCheckerPage = () => {
  const tenantConfig = useSelector(selectTenantConfig)
  const user = useSelector(selectProfile)
  const dispatch = useDispatch()

  const history = useHistory()
  const goToChecker = () => {
    history.push(ROUTES.CHECKER)
  }

  React.useEffect(() => {
    dispatch(getTenantConfig())
    dispatch(setProfile())
  }, [])
  useIsFirstLogin()

  if (!tenantConfig || !user) return null

  return (
    <div className={`${styles.fullSettingsFrame} container pb-5 mt-3 flex-grow d-block`} data-page-title="Checker">
      <div className={`${styles.checkerContainer} d-flex`}>
        <div className={styles.content}>
          <div>
            <img src={tenantConfig.networkLogoInThemeColor} className={styles.logoImage} alt="logo" />
          </div>
          <div className={styles.mainText} style={{ color: tenantConfig.appThemeColor }}>
            Hi, {user.firstName}
            <br />
            Time for your daily self-assessment
          </div>
          <div className={styles.secondText}>
            Please complete this self-assessment - built using CDC guidance and in partnership with the
            {` ${tenantConfig.name}`} - before each work day.
          </div>
        </div>
        <div className={styles.checkerButtonContainer}>
          <ThemeButton onClick={goToChecker} type={'standard'}>
            Begin
          </ThemeButton>
        </div>
      </div>
    </div>
  )
}
