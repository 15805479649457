import classnames from 'classnames'
import * as React from 'react'
import classes from './SvgIcon.module.css'

export const SvgIcon: React.FC<Props> = ({
  children,
  className,
  color,
  titleAccess,
  viewBox = '0 0 24 24',
  shapeRendering,
}) => {
  return (
    <svg
      className={classnames(classes.root, className)}
      focusable="false"
      viewBox={viewBox}
      color={color}
      aria-hidden={titleAccess ? undefined : true}
      role={titleAccess ? 'img' : undefined}
      shapeRendering={shapeRendering}
    >
      {children}
      {titleAccess ? <title>{titleAccess}</title> : null}
    </svg>
  )
}

type Props = {
  children: React.ReactNode
  className: string
  color: string
  shapeRendering: string
  titleAccess: string
  viewBox: string
}
