import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ROUTES } from '../../constatants'
import { useIsFirstLogin } from '../../features/auth0'
import { getResources, selectResourcesList } from '../../features/resourcesSlice'
import { Loading } from '../../ui'
import styles from './Resource.module.css'

interface Props {
  match: any
  history: any
}

type ResourceType = {
  channelIDs: null | string
  description: string
  id: string
  largeImageURL: null | string
  mediaURL: null | string
  playerURL: null | string
  size: 0
  thumbnailURL: string
  title: string
}

export function Resource({ history, match }: Props) {
  const dispatch = useDispatch()
  const list = useSelector(selectResourcesList)
  const [infoActive, toggleInfo] = useState(false)
  const [resource, setResource] = useState<ResourceType>(null)
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    ;(async function () {
      await dispatch(getResources())
      // await dispatch(getPrimaryResource())
      setLoading(false)
    })()
  }, [])
  useIsFirstLogin()

  const goToResources = () => {
    history.push(ROUTES.RESOURCES)
  }

  useEffect(() => {
    if (list.length) {
      const res = list.find((i) => i.id === match.params.id)
      if (res) {
        setResource(res)
        setTimeout(() => {
          window.flowplayer('#player', {
            src: res.mediaURL,
            token:
              'eyJraWQiOiJ2U25ORVZyT1dvZksiLCJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiJ9.eyJjIjoie1wiYWNsXCI6NCxcImlkXCI6XCJ2U25ORVZyT1dvZktcIn0iLCJpc3MiOiJGbG93cGxheWVyIn0.6dp1aM81dzVu8kaCzPwzI3D050_mVG5GieHSQDPf94rXa12FGmsizxv6deA507-wcNwBDSO2JLhIxDC7K7dvAg',
            title: res.player,
            autoplay: true,
            mute: false,
            volume: 1,
            ratio: '16/9',
          })
        }, 1000)
      }
    }
  }, [list])

  if (loading) return <Loading />

  return (
    <div className={`${styles.fullSettingsFrame} container pt-0 mb-5 mt-3`}>
      <div className="row">
        <div className={`${styles.providerAppHolder} col-md-12`}>
          <div className="my-3 pl-3">
            <a onClick={goToResources} className={`${styles.arrowBack} cursor-pointer`}>
              <i className="fa fa-arrow-left color-safe mr-2" />
              <span className="color-safe">Go back</span>
            </a>
          </div>
          {resource.thumbnailURL && <img src={resource.thumbnailURL} alt="thumbnail" className={styles.image} />}
          {resource.mediaURL && <div style={{ minHeight: 200 }} id={'player'} />}
          <div className="p-3 pb-4">
            <div className={`${styles.diseaseTitle} h5 mb-3`}>{resource.title}</div>
            <div className={`${styles.diseaseInfo} d-flex flex-column mb-3 ${infoActive ? 'active' : ''}`}>
              <div
                className={`${styles.diseaseInfoInner}`}
                dangerouslySetInnerHTML={{ __html: resource.description }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
