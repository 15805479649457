import moment from 'moment-timezone'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ROUTES } from '../../constatants'
import { getTenantConfig } from '../../features/tenantConfigSlice'
import { getTenantName } from '../../libs/helpers'
import { Loading } from '../../ui'
import styles from './SelfAssessmentHistory.module.css'
import { getAssessmentHistory, selectAssessmentHistory, reset } from './assessmentHistorySlice'

export function SelfAssessmentHistory({ history }: RouteProps) {
  let state = useSelector(selectAssessmentHistory)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getTenantConfig())
    dispatch(reset())
    dispatch(getAssessmentHistory())
  }, [])

  if (state.assessmentHistory.initializing || getTenantName() !== 'delta') return <Loading />

  const goToRewardsInfo = () => {
    history.push(ROUTES.REWARDS)
  }

  const TitleView = () => {
    let daysAssessed = `${state.assessmentHistory.totalAssessmentDays} days`
    if (state.assessmentHistory.totalAssessmentDays === 1) {
      daysAssessed = '1 day'
    }

    return (
      <div className={`${styles.titleContainer} col-md-12`}>
        <div className={`${styles.assessmentTitle} text-center mb-1`}>
          <div>{`You've self-assessed for ${daysAssessed}.`}</div>
          <span>Keep it up!</span>
        </div>
        <div className={`${styles.rewardsButtonContainer} text-center`}>
          <button
            style={{ border: 0, background: 'transparent' }}
            className={`${styles.rewardsButton} text-center`}
            onClick={goToRewardsInfo}
            type={'button'}
          >
            ABOUT DELTA HEALTH REWARDS
          </button>
        </div>
      </div>
    )
  }

  const SelfAssessmentTableCell = (props) => {
    const { items } = props

    if (items.length === 0) {
      return <div className={`${styles.noAssessment}`}>Assessment Not Taken</div>
    }

    return (
      <div className={`${styles.assessmentItemHolder}`}>
        <ul>
          {items.map((assessment) => (
            <li key={assessment.id} className={`${styles.assessmentItem}`}>
              <span>
                {`${moment(assessment.completed || assessment.started)
                  .tz(state.tenantConfig.reportingTimezone)
                  .format('h:mm A z')}`}
              </span>
              <span className={`${assessment.isCleared ? styles.assessmentComplete : styles.assessmentIncomplete}`}>
                {assessment.isCleared ? 'Cleared For Work' : ' Not Cleared For Work'}
              </span>
            </li>
          ))}
        </ul>
      </div>
    )
  }

  const SelfAssessmentTableSection = (props) => {
    const { assessment } = props
    const classes = [`${styles.selfAssessmentCell}`]
    if (assessment.items.length === 0) {
      classes.push(`${styles.emptyCell}`)
    }

    return (
      <li key={assessment.id} className={classes.join(' ')}>
        <span className={`${styles.selfAssessmentTitleInfo} w-100 d-flex align-items-center`}>
          <div>{assessment.id}</div>
        </span>
        <SelfAssessmentTableCell items={assessment.items} />
      </li>
    )
  }

  const NoSelfAssessmentHistory = () => <p style={{ margin: 0, textAlign: 'center' }}>No self assessments</p>

  const SelfAssessmentTable = () => {
    return (
      <div className={`col-md-12`}>
        <div className={`${styles.selfAssessmentHolder} my-3`}>
          <ul className={`${styles.borderRadius5} bg-white d-flex flex-column list-unstyled pt-0 pr-0 pb-0 m-0`}>
            {!state.assessmentHistory.items.length && <NoSelfAssessmentHistory />}
            {state.assessmentHistory.items.map((assessment) => (
              <SelfAssessmentTableSection key={assessment.id} assessment={assessment} />
            ))}
          </ul>
        </div>
      </div>
    )
  }

  return (
    <div className={`${styles.fullFrame} container-fluid mb-5 mt-3`}>
      <div className="row">
        <TitleView />
        <SelfAssessmentTable />
      </div>
    </div>
  )
}
