import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import classNames from 'classnames'
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ReactComponent as GreenCheckmark } from '../../assets/checkmark-green.svg'
import { ROUTES } from '../../constatants'
import { selectTenantConfig } from '../../features/tenantConfigSlice'
import { selectProfile, resetPassword } from '../../features/userSlice'
import { TextField, IconButton } from '../../ui'
import styles from './NewPassword.module.css'

type Validation = {
  minLength: boolean
  uppercase: boolean
  lowercase: boolean
  digit: boolean
  special: boolean
}

export function NewPassword() {
  const history = useHistory()
  const [passwordValidation, setPasswordValidation] = React.useState<Validation>({
    minLength: false,
    uppercase: false,
    lowercase: false,
    digit: false,
    special: false,
  })
  const [password, setPassword] = React.useState('')
  const [isDisabled, setIsDisabled] = React.useState(true)
  const [passwordHelperText, setPasswordHelperText] = React.useState('')
  const [isShowPassword, setIsShowPassword] = useState(false)

  const tenantConfig = useSelector(selectTenantConfig)
  const user = useSelector(selectProfile)

  const dispatch = useDispatch()

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setPassword(value)
    checkFormValidation({ password: value })
  }

  const isPasswordValid = (pw) => {
    const validation: Validation = {
      minLength: pw.length >= 8,
      uppercase: /([A-Z])/.test(pw),
      lowercase: /([a-z])/.test(pw),
      digit: /([0-9])/.test(pw),
      special: /([^A-Za-z0-9])/.test(pw),
    }
    setPasswordValidation(validation)

    if (Object.values(validation).includes(false)) {
      setIsDisabled(true)
      return false
    }
    return true
  }
  const checkFormValidation = (values: Partial<{ password: string }>) => {
    const pw = values.password === undefined ? password : values.password
    if (!isPasswordValid(pw)) return false
    setIsDisabled(false)
    return true
  }

  const isFormValid = () => {
    if (Object.values(passwordValidation).includes(false)) return false
    return true
  }

  const handleSubmit = async (e: React.FormEvent) => {
    setIsDisabled(true)
    e.preventDefault()
    if (!isFormValid()) return
    if (user && user.email) {
      const errorMessage = await dispatch(resetPassword({ id: user.email, password }))
      if (errorMessage) setPasswordHelperText(errorMessage as any)
      else history.push(ROUTES.ACCOUNT_INFO)

      setIsDisabled(false)
    }
  }
  const toggleShowPassword = () => setIsShowPassword(!isShowPassword)

  return (
    <div className={`${styles.fullSettingsFrame} container-fluid p-0 mt-3`}>
      <form className="form-signin mt-0 mb-0" onSubmit={handleSubmit}>
        <div className={styles.setPasswordWrapper}>
          <div className={styles.setText}>Account Info</div>
        </div>
        <div className={styles.setPasswordForm}>
          <TextField
            value={password}
            onChange={handlePasswordChange}
            type={isShowPassword ? 'text' : 'password'}
            label="Password"
            endAdornment={
              <IconButton onClick={toggleShowPassword} className={styles.showPasswordBtn}>
                {isShowPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            }
            error={passwordHelperText}
            required
          />
          <ErrorsWrapper>
            <ErrorWrapper>
              {passwordValidation.minLength ? <GreenCheckmark /> : <GreenCheckmark className={styles.checkError} />}
              <ErrorLabel>At least 8 characters</ErrorLabel>
            </ErrorWrapper>
            <ErrorWrapper>
              {passwordValidation.uppercase ? <GreenCheckmark /> : <GreenCheckmark className={styles.checkError} />}
              <ErrorLabel>1 Uppercase letter</ErrorLabel>
            </ErrorWrapper>
            <ErrorWrapper>
              {passwordValidation.lowercase ? <GreenCheckmark /> : <GreenCheckmark className={styles.checkError} />}
              <ErrorLabel>1 Lowercase letter</ErrorLabel>
            </ErrorWrapper>
            <ErrorWrapper>
              {passwordValidation.digit ? <GreenCheckmark /> : <GreenCheckmark className={styles.checkError} />}
              <ErrorLabel>1 Number</ErrorLabel>
            </ErrorWrapper>
            <ErrorWrapper fullWidth>
              {passwordValidation.special ? <GreenCheckmark /> : <GreenCheckmark className={styles.checkError} />}
              <ErrorLabel>1 Special Character (!@#$%^&*)</ErrorLabel>
            </ErrorWrapper>
          </ErrorsWrapper>

          <div className={styles.signinButtonWrapper}>
            <button
              style={{ backgroundColor: tenantConfig.appThemeColor }}
              className={`${styles.noOutline} ${styles.submitBtn} btn btn-lg btn-block text-white d-flex align-items-center justify-content-center`}
              type="submit"
              disabled={isDisabled}
            >
              Continue
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

const ErrorLabel: React.FC = ({ children }) => {
  return <span className={styles.errorLabel}>{children}</span>
}
const ErrorsWrapper: React.FC = ({ children }) => {
  return <div className={styles.errorsWrapper}>{children}</div>
}
const ErrorWrapper: React.FC<{ fullWidth?: boolean }> = ({ children, fullWidth = false }) => {
  return <div className={classNames(styles.errorWrapper, { [styles.fullWidthError]: fullWidth })}>{children}</div>
}
