import * as firebase from 'firebase/app'
import { buildFirebaseConfig } from '../helpers'
import 'firebase/messaging'

const { REACT_APP_DEV_FIREBASE_VAPID_KEY } = process.env

function createMessaging() {
  const firebaseConfig = buildFirebaseConfig()
  const initializedFirebaseApp = firebase.initializeApp(firebaseConfig)

  if (!firebase.messaging.isSupported()) {
    console.log('Firebase cloud messaging is not supporting')
    return null
  }

  const messaging = initializedFirebaseApp.messaging()
  messaging.usePublicVapidKey(REACT_APP_DEV_FIREBASE_VAPID_KEY)

  return messaging
}

export { createMessaging }
